<template>
      <div>
            <div class="eManage_challenge" style="height: 230px;">
                  <p class="eManage_challenge_p">背景</p>
                  <div class="eMove_introduction_wrap">
                        <div class="eMove_introduction_div">
                              <p class="eMove_introduction_div_p">
                                    对安全的长期数据保存的要求无处不在而且变得日益严格，进而导致了数据量的快速增长，在金融服务、医疗和生命科学行业尤其如此。与磁盘驱动器或闪存相比，磁带存储是一种成本更低的长期存储选项。以离线方式保存在磁带中或一次性写入介质中的数据几乎不可能被黑客攻击。
                              </p>
                        </div>
                  </div>
            </div>
            <div class="eManage_challenge" style="height: 230px;">
                  <p class="eManage_challenge_p" style="padding-top: 4%;">简介</p>
                  <div class="eMove_introduction_wrap">
                        <div class="eMove_introduction_div">
                              <p class="eMove_introduction_div_p">
                                    LinkedStor
                                    ADS磁带库是一种高密度、高度可扩展而且易于管理的磁带库，设计用于帮助长期安全地保存数据，同时帮助降低与数据中心空间和设施相关的成本。它的模块化设计使用户可以根据需要来增加磁带盒和驱动器容量。
                              </p>
                        </div>
                  </div>
            </div>
            <div class="eMove_function">
                  <div class="eMove_function_background">
                        <img class="eMove_function_background_img" :src="background" />
                  </div>
                  <div class="eMove_function_wrap">
                        <div class="eMove_function_content">
                              <p class="eMove_function_content_p">功能与设计</p>
                              <div class="eMove_function_content_div">
                                    <div class="content_left">
                                          <div class="content_left_wrap">
                                                <div class="content_left_wrap_div_img">
                                                      <img class="login-icon" :src="denglu" />
                                                </div>
                                                <p class="content_left_wrap_p1">可扩展、易用、高性价比</p>
                                                <p class="content_left_wrap_p2" style="text-align: start;">
                                                      • LinkedStor ADS磁带库提供超越传统磁带解决方案的可扩展性，使您可以随着业务扩展您的数据环境。<br>
                                                      • 使用易于订购、配置，保持简单、节约时间并支持成本。<br>
                                                      • 长达30年的媒体寿命，磁带技术的总拥有成本极低。</p>
                                          </div>
                                    </div>
                                    <div class="content_right">
                                          <div class="content_top">
                                                <div class="content_top_left">
                                                      <div class="content_top_left_wrap">
                                                            <div class="content_top_left_img">
                                                                  <img class="auto-icon" :src="shipai" />
                                                            </div>
                                                            <p class="content_top_left_p1">自动数据保护</p>
                                                            <p class="content_top_left_p2" style="text-align: start;">
                                                                  • 严格的定期备份是全面灾难恢复策略的重要组成部分。<br>
                                                                  • 功能强大的磁带库，有助于组织自动完成其备份过程，以减少人工干预的需要和人为错误的风险。<br>
                                                                  • 使用光学磁带盒定位技术，用于精确处理和清点磁带盒，有助于提高备份的整体可靠性。<br>

                                                            </p>
                                                      </div>
                                                </div>
                                                <div class="content_top_right">
                                                      <div class="content_top_right_wrap">
                                                            <div class="content_top_right_img">
                                                                  <img class="safe-icon" :src="anquan" />
                                                            </div>
                                                            <p class="content_top_right_p1">简化操作</p>
                                                            <p class="content_top_right_p2" style="text-align: start;">
                                                                  •
                                                                  使用邮件槽可以单独导入和导出磁带，或使用两个磁带仓之一每次导入16个或20个磁带。磁带库利用条形码扫描技术记录磁带介质位置，因此在灾难发生时，您的关键数据能够得以适当存储并随时可用，让您轻松省心。
                                                            </p>
                                                      </div>
                                                </div>
                                          </div>
                                          <div class="content_top">
                                                <div class="content_top_left">
                                                      <div class="content_top_left_wrap">
                                                            <div class="content_top_left_img">
                                                                  <img class="auto-icon" :src="shipai" />
                                                            </div>
                                                            <p class="content_top_left_p1">直观管理</p>
                                                            <p class="content_top_left_p2" style="text-align: start;">
                                                                  •
                                                                  提供便于用户使用的管理界面，让您轻松监控、控制、配置和维护磁带库。在您外出时，利用磁带库，您将能够远程管理各种功能，包括系统状态和日志、诊断和驱动器操作，以及配置和清单管理功能。
                                                            </p>
                                                      </div>
                                                </div>
                                                <div class="content_top_right">
                                                      <div class="content_top_right_wrap">
                                                            <div class="content_top_right_img">
                                                                  <img class="safe-icon" :src="anquan" />
                                                            </div>
                                                            <p class="content_top_right_p1">磁带通用性</p>
                                                            <p class="content_top_right_p2" style="text-align: start;">
                                                                  • 磁带成本相对较低，且具备长期验证的可靠性 -
                                                                  有助于确保其持续用于备份和归档。磁带的便携性使之能够外出携带并存储在数据仓中。此外，LTO磁带技术支持一写多读(WORM)功能，该功能可永久存储数据且不会覆盖数据
                                                                  - 这也是许多合规性法规的要求。</p>
                                                      </div>
                                                </div>
                                          </div>
                                          <div class="content_botton">
                                                <div class="content_botton_left">
                                                      <div class="content_botton_left_wrap">
                                                            <div class="content_botton_left_img">
                                                                  <img class="point-icon" :src="duandian" />
                                                            </div>
                                                            <p class="content_botton_left_p1">容量大，空间小</p>
                                                            <p class="content_botton_left_p2"
                                                                  style="text-align: start;">
                                                                  • 凭借紧凑的外形，LinkedStor
                                                                  ADS可在非常小的空间里存储大量信息。该磁带库仅占用三个单位(3U)的机架空间，可容纳多达40个磁带，且每个LinkedStor
                                                                  ADS扩展可容纳多达40个磁带 - 是管理爆炸性数据增长的小型办公环境的理想选择。</p>
                                                      </div>
                                                </div>
                                                <div class="content_botton_right">
                                                      <div class="content_botton_right_wrap">
                                                            <div class="content_botton_right_img">
                                                                  <img class="monitor-icon" :src="jiankong" />
                                                            </div>
                                                            <p class="content_botton_right_p1">绿色环保</p>
                                                            <p class="content_botton_right_p2"
                                                                  style="text-align: start;">
                                                                  •
                                                                  磁带存储的可靠性、便携性、加密性以及磁带的长久保存期限（超过30年），堪称一款完美的解决方案，可满足您的长期和短期备份或归档需求。此外，磁带介质一经存储，则无需耗电，除非再次对其进行访问。由于存储后完全不需要耗费能源，磁带“自然”环保
                                                            </p>
                                                      </div>
                                                </div>
                                          </div>

                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</template>

<script>
export default {
      name: "EManageChallenge",
      data() {
            return {
                  fengxian: require('@/assets/eManage/eManage_challenge/3.png'),
                  xiaolv: require('@/assets/eManage/eManage_challenge/2.png'),
                  yunying: require('@/assets/eManage/eManage_challenge/1.png'),
                  background: require('@/assets/eMove/eMove-function/background.png'),
                  denglu: require('@/assets/eMove/eMove-function/5.png'),
                  shipai: require('@/assets/eMove/eMove-function/4.png'),
                  anquan: require('@/assets/eMove/eMove-function/3.png'),
                  duandian: require('@/assets/eMove/eMove-function/2.png'),
                  jiankong: require('@/assets/eMove/eMove-function/1.png'),
            }
      },
      methods: {},
      created() { },
      computed: {},
      watch: {},
}
</script>

<style lang="scss" scoped>
.eMove_introduction_wrap {
      margin-top: 29px;
      width: 100%;
      height: 78px;
      /* 子元素居中 */
      display: flex;
      justify-content: center;

      /* 子元素水平居中 */
      .eMove_introduction_div {
            width: 941.55px;
            height: 77.52px;

            .eMove_introduction_div_p {
                  width: 941.55px;
                  height: 77.52px;
                  opacity: 1;
                  /** 文本1 */
                  font-size: 15.96px;
                  font-weight: 300;
                  letter-spacing: 0;
                  line-height: 23.11px;
                  color: rgba(102, 102, 102, 1);
                  text-align: center;
                  vertical-align: top;
            }
      }
}

.eManage_challenge {
      width: 1920px;
      height: 620px;

      .eManage_challenge_p {
            padding-top: 9%;
            width: 1920px;
            height: 47px;
            opacity: 1;
            /** 文本1 */
            font-size: 31.92px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 47px;
            color: rgba(34, 34, 34, 1);
            text-align: center;
            vertical-align: top;
      }

      .eManage_challenge_wrap {
            margin-top: 35px;
            width: 1920px;
            height: 199px;
            display: flex;
            justify-content: center;

            .eManage_challenge_content {
                  width: 1397px;
                  height: 199px;

                  .eManage_challenge_content_div1 {
                        float: left;
                        margin-right: 40px;
                        width: 439px;
                        height: 199px;
                        background: rgba(245, 248, 255, 1);

                        .eManage_challenge_div1_wrap {
                              margin-top: 53px;
                              margin-left: 41px;
                              width: 345px;
                              height: 139px;

                              .eManage_challenge_div1_img {
                                    float: left;
                                    margin-top: 8px;
                                    width: 62px;
                                    height: 70px;
                              }

                              .eManage_challenge_div1_right {
                                    float: left;
                                    margin-left: 27px;
                                    width: 256px;
                                    height: 139px;

                                    .eManage_challenge_div1_right_p1 {
                                          width: 100%;
                                          height: 35px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 23.94px;
                                          font-weight: 400;
                                          letter-spacing: 0;
                                          line-height: 34.66px;
                                          color: rgba(51, 51, 51, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }

                                    .eManage_challenge_div1_right_p2 {
                                          margin-top: 10px;
                                          width: 255.83px;
                                          height: 94.08px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 15.96px;
                                          font-weight: 300;
                                          letter-spacing: 0;
                                          line-height: 23.11px;
                                          color: rgba(102, 102, 102, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }
                              }
                        }
                  }

                  .eManage_challenge_content_div2 {
                        float: left;
                        margin-right: 40px;
                        width: 439px;
                        height: 199px;
                        background: rgba(245, 248, 255, 1);

                        .eManage_challenge_div2_wrap {
                              margin-top: 53px;
                              margin-left: 35px;
                              width: 362px;
                              height: 131px;

                              .eManage_challenge_div2_img {
                                    float: left;
                                    margin-top: 1px;
                                    width: 56px;
                                    height: 70px;
                              }

                              .eManage_challenge_div2_right {
                                    float: left;
                                    margin-left: 28px;
                                    width: 278px;
                                    height: 131px;

                                    .eManage_challenge_div2_right_p1 {
                                          width: 100%;
                                          height: 35px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 23.94px;
                                          font-weight: 400;
                                          letter-spacing: 0;
                                          line-height: 34.66px;
                                          color: rgba(51, 51, 51, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }

                                    .eManage_challenge_div2_right_p2 {
                                          margin-top: 10px;
                                          width: 277.78px;
                                          height: 86.11px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 15.96px;
                                          font-weight: 300;
                                          letter-spacing: 0;
                                          line-height: 23.11px;
                                          color: rgba(102, 102, 102, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }
                              }
                        }
                  }

                  .eManage_challenge_content_div3 {
                        float: left;
                        width: 439px;
                        height: 199px;
                        background: rgba(245, 248, 255, 1);

                        .eManage_challenge_div3_wrap {
                              margin-top: 53px;
                              margin-left: 41px;
                              width: 345px;
                              height: 133px;

                              .eManage_challenge_div3_img {
                                    float: left;
                                    margin-top: 3px;
                                    width: 55px;
                                    height: 64px;
                              }

                              .eManage_challenge_div3_right {
                                    float: left;
                                    margin-left: 34px;
                                    width: 256px;
                                    height: 133px;

                                    .eManage_challenge_div3_right_p1 {
                                          width: 100%;
                                          height: 34.91px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 23.94px;
                                          font-weight: 400;
                                          letter-spacing: 0;
                                          line-height: 34.66px;
                                          color: rgba(51, 51, 51, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }

                                    .eManage_challenge_div3_right_p2 {
                                          margin-top: 10px;
                                          width: 255.83px;
                                          height: 88.1px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 15.96px;
                                          font-weight: 300;
                                          letter-spacing: 0;
                                          line-height: 23.11px;
                                          color: rgba(102, 102, 102, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }
                              }
                        }
                  }
            }
      }
}

.eMove_function {
      position: relative;
      width: 1920px;
      height: 1550px;

      .eMove_function_background {
            position: absolute;
            width: 100%;
            height: 892px;

            .eMove_function_background_img {
                  width: 100%;
                  height: 1550px;

            }
      }

      .eMove_function_wrap {
            position: absolute;
            width: 100%;
            height: 892px;
            /* 子元素居中 */
            display: flex;
            justify-content: center;

            /* 子元素水平居中 */
            .eMove_function_content {
                  width: 1397px;
                  height: 810px;

                  .eMove_function_content_p {
                        margin-top: 70px;
                        width: 100%;
                        height: 47px;
                        opacity: 1;
                        /** 文本1 */
                        font-size: 31.92px;
                        font-weight: 700;
                        letter-spacing: 0;
                        line-height: 46.22px;
                        color: rgba(51, 51, 51, 1);
                        text-align: center;
                        vertical-align: top;
                  }

                  .eMove_function_content_div {
                        margin-top: 55px;
                        width: 1397px;
                        height: 638px;

                        .content_left {
                              float: left;
                              display: flex;
                              justify-content: center;
                              margin-right: 40px;
                              width: 439px;
                              height: 1280px;
                              background: rgba(255, 255, 255, 1);
                              box-shadow: 0px 34.91px 75.8px rgba(0, 0, 0, 0.06);

                              .content_left_wrap {
                                    margin-top: 184px;
                                    width: 344px;
                                    height: 294px;

                                    .content_left_wrap_div_img {
                                          width: 100%;
                                          height: 55px;
                                          display: flex;
                                          justify-content: center;

                                          .login-icon {
                                                width: 68px;
                                                height: 55px;
                                          }
                                    }

                                    .content_left_wrap_p1 {
                                          margin-top: 54px;
                                          width: 100%;
                                          height: 31.92px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 21.94px;
                                          font-weight: 400;
                                          letter-spacing: 0;
                                          line-height: 31.78px;
                                          color: rgba(51, 51, 51, 1);
                                          text-align: center;
                                          vertical-align: top;
                                    }

                                    .content_left_wrap_p2 {
                                          margin-top: 19px;
                                          width: 100%;
                                          height: 133.98px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 15.96px;
                                          font-weight: 300;
                                          letter-spacing: 0;
                                          line-height: 23.11px;
                                          color: rgba(102, 102, 102, 1);
                                          text-align: center;
                                          vertical-align: top;
                                    }
                              }
                        }

                        .content_right {
                              float: left;
                              width: 918px;
                              height: 638px;

                              .content_top {
                                    margin-bottom: 40px;
                                    width: 918px;
                                    height: 400px;

                                    .content_top_left {
                                          float: left;
                                          margin-right: 40px;
                                          width: 439px;
                                          height: 400px;
                                          background: rgba(255, 255, 255, 1);
                                          box-shadow: 0px 34.91px 75.8px rgba(0, 0, 0, 0.06);
                                          display: flex;
                                          justify-content: center;

                                          .content_top_left_wrap {
                                                margin-top: 53px;
                                                width: 357px;
                                                height: 247px;

                                                .content_top_left_img {
                                                      width: 100%;
                                                      height: 66px;

                                                      .auto-icon {
                                                            width: 68px;
                                                            height: 55px;
                                                      }
                                                }

                                                .content_top_left_p1 {
                                                      margin-top: 24px;
                                                      width: 100%;
                                                      height: 31.92px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 21.94px;
                                                      font-weight: 400;
                                                      letter-spacing: 0;
                                                      line-height: 31.78px;
                                                      color: rgba(51, 51, 51, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }

                                                .content_top_left_p2 {
                                                      margin-top: 19px;
                                                      width: 100%;
                                                      height: 106px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 15.96px;
                                                      font-weight: 300;
                                                      letter-spacing: 0;
                                                      line-height: 23.11px;
                                                      color: rgba(102, 102, 102, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }
                                          }

                                    }

                                    .content_top_right {
                                          float: left;
                                          width: 439px;
                                          height: 400px;
                                          background: rgba(255, 255, 255, 1);
                                          box-shadow: 0px 34.91px 75.8px rgba(0, 0, 0, 0.06);
                                          display: flex;
                                          justify-content: center;

                                          .content_top_right_wrap {
                                                margin-top: 48px;
                                                width: 363px;
                                                height: 252px;

                                                .content_top_right_img {
                                                      width: 100%;
                                                      height: 74px;

                                                      .safe-icon {
                                                            width: 68px;
                                                            height: 55px;
                                                      }
                                                }

                                                .content_top_right_p1 {
                                                      margin-top: 21px;
                                                      width: 100%;
                                                      height: 31.92px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 21.94px;
                                                      font-weight: 400;
                                                      letter-spacing: 0px;
                                                      line-height: 31.78px;
                                                      color: rgba(51, 51, 51, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }

                                                .content_top_right_p2 {
                                                      margin-top: 19px;
                                                      width: 100%;
                                                      height: 106px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 15.96px;
                                                      font-weight: 300;
                                                      letter-spacing: 0;
                                                      line-height: 23.11px;
                                                      color: rgba(102, 102, 102, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }
                                          }
                                    }
                              }

                              .content_botton {
                                    width: 918px;
                                    height: 400px;

                                    .content_botton_left {
                                          float: left;
                                          margin-right: 40px;
                                          width: 439px;
                                          height: 400px;
                                          background: rgba(255, 255, 255, 1);
                                          box-shadow: 0px 34.91px 75.8px rgba(0, 0, 0, 0.06);
                                          display: flex;
                                          justify-content: center;

                                          .content_botton_left_wrap {
                                                margin-top: 51px;
                                                width: 357px;
                                                height: 240px;

                                                .content_botton_left_img {
                                                      width: 100%;
                                                      height: 69px;

                                                      .point-icon {
                                                            width: 68px;
                                                            height: 55px;
                                                      }
                                                }

                                                .content_botton_left_p1 {
                                                      margin-top: 23px;
                                                      width: 100%;
                                                      height: 31.92px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 21.94px;
                                                      font-weight: 400;
                                                      letter-spacing: 0;
                                                      line-height: 31.78px;
                                                      color: rgba(51, 51, 51, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }

                                                .content_botton_left_p2 {
                                                      margin-top: 19px;
                                                      width: 100%;
                                                      height: 97.08px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 15.96px;
                                                      font-weight: 300;
                                                      letter-spacing: 0;
                                                      line-height: 23.11px;
                                                      color: rgba(102, 102, 102, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }
                                          }
                                    }

                                    .content_botton_right {
                                          float: left;
                                          width: 439px;
                                          height: 400px;
                                          background: rgba(255, 255, 255, 1);
                                          box-shadow: 0px 34.91px 75.8px rgba(0, 0, 0, 0.06);
                                          display: flex;
                                          justify-content: center;

                                          .content_botton_right_wrap {
                                                margin-top: 51px;
                                                width: 351px;
                                                height: 217px;

                                                .content_botton_right_img {
                                                      width: 100%;
                                                      height: 57px;

                                                      .monitor-icon {
                                                            width: 68px;
                                                            height: 55px;
                                                      }
                                                }

                                                .content_botton_right_p1 {
                                                      margin-top: 31px;
                                                      width: 100%;
                                                      height: 31.92px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 21.94px;
                                                      font-weight: 400;
                                                      letter-spacing: 0;
                                                      line-height: 31.78px;
                                                      color: rgba(51, 51, 51, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }

                                                .content_botton_right_p2 {
                                                      margin-top: 19px;
                                                      width: 100%;
                                                      height: 77.63px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 15.96px;
                                                      font-weight: 300;
                                                      letter-spacing: 0;
                                                      line-height: 23.11px;
                                                      color: rgba(102, 102, 102, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}
</style>