<template>
	<div class="eMove_introduction">
		<p class="eMove_introduction_p">简介</p>
		<div class="eMove_introduction_wrap">
			<div class="eMove_introduction_div">
				<p class="eMove_introduction_div_p">e键搬工具是一款专业的云电脑迁移工具，主要为云电脑用户提供将本地环境一键搬家上云的能力，含括文件搬家、用户搬家及多款应用搬家，无论是个人用户还是企业用户，都可以通过该软件实现一键迁移，享受云端的便利性和灵活性。</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EMoveIntroduction",
	data(){
		return {
		}
	},
	methods:{
	},
	created() {

	},
	computed:{
	},
	watch:{

	},
}
</script>

<style lang="scss" scoped>
.eMove_introduction{
	width: 1920px;
	height: 350px;
	.eMove_introduction_p{
		padding-top: 9%;
		width: 100%;
		height: 46.88px;
		opacity: 1;
		/** 文本1 */
		font-size: 31.92px;
		font-weight: 700;
		letter-spacing: 0;
		line-height: 46.22px;
		color: rgba(34, 34, 34, 1);
		text-align: center;
		vertical-align: top;
	}
	.eMove_introduction_wrap{
		margin-top: 29px;
		width: 100%;
		height: 78px;
		/* 子元素居中 */
		display: flex;
		justify-content: center; /* 子元素水平居中 */
		.eMove_introduction_div{
			width: 941.55px;
			height: 77.52px;
			.eMove_introduction_div_p{
				width: 941.55px;
				height: 77.52px;
				opacity: 1;
				/** 文本1 */
				font-size: 15.96px;
				font-weight: 300;
				letter-spacing: 0;
				line-height: 23.11px;
				color: rgba(102, 102, 102, 1);
				text-align: center;
				vertical-align: top;
			}
		}
	}
}
</style>