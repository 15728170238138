<template>
	<div class="eMove_advantage">
		<p class="eMove_advantage_p">优势</p>
		<div class="eMove_advantage_wrap">
			<div class="eMove_advantage_wrap_div">
				<div class="div1">
					<div class="div1_left">
						<p class="div1_left_p1">业务连续</p>
						<p class="div1_left_p2">不停机实现业务持续传输上云</p>
					</div>
					<div class="div1_right">
						<img class="div1_right-img" :src="yewu"/>
					</div>
				</div>
				<div class="div2">
					<div class="div2_left">
						<p class="div2_left_p1">批量启动</p>
						<p class="div2_left_p2">目标云端平台无需提前一对一启动和配置实例</p>
					</div>
					<div class="div2_right">
						<img class="div2_right-img" :src="piliang"/>
					</div>
				</div>
				<div class="div3">
					<div class="div3_left">
						<p class="div3_left_p1">异构适配</p>
						<p class="div3_left_p2">异构平台驱动自动智能转换适配，无需人为介入操作</p>
					</div>
					<div class="div3_right">
						<img class="div3_right-img" :src="yigou"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EMigrateAdvantage",
	data(){
		return {
			yewu: require('@/assets/eMigrate/eMigrate-advantage/3.png'),
			piliang: require('@/assets/eMigrate/eMigrate-advantage/2.png'),
			yigou: require('@/assets/eMigrate/eMigrate-advantage/1.png'),
		}
	},
	methods:{

	},
	created() {

	},
	computed:{

	},
	watch:{

	},
}
</script>

<style lang="scss" scoped>
.eMove_advantage{
	width: 1920px;
	height: 417px;
	.eMove_advantage_p{
		padding-top: 46px;
		width: 100%;
		height: 47px;
		opacity: 1;
		/** 文本1 */
		font-size: 31.92px;
		font-weight: 700;
		letter-spacing: 0;
		line-height: 46.22px;
		color: rgba(34, 34, 34, 1);
		text-align: center;
		vertical-align: top;
	}
	.eMove_advantage_wrap{
		margin-top: 35px;
		width: 100%;
		height: 180px;
		/* 子元素居中 */
		display: flex;
		justify-content: center; /* 子元素水平居中 */
		.eMove_advantage_wrap_div{
			width: 1405px;
			height: 180px;
			.div1{
				float: left;
				margin-right: 40px;
				width: 439px;
				height: 180px;
				background: rgba(245, 248, 255, 1);
				.div1_left{
					float: left;
					margin-top: 34px;
					margin-left: 41px;
					width: 247px;
					height: 201px;
					.div1_left_p1{
						width: 100%;
						height: 34.91px;
						opacity: 1;
						/** 文本1 */
						font-size: 23.94px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 34.66px;
						color: rgba(51, 51, 51, 1);
						text-align: left;
						vertical-align: top;
					}
					.div1_left_p2{
						margin-top: 11px;
						width: 100%;
						height: 155.27px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(102, 102, 102, 1);
						text-align: left;
						vertical-align: top;
					}
				}
				.div1_right{
					float: left;
					margin-top: 88px;
					margin-left: 50px;
					width: 69px;
					height: 69px;
          .div1_right-img {
            width: 69px;
            height: 69px;
          }
				}
			}
			.div2{
				float: left;
				margin-right: 38px;
				width: 439px;
				height: 180px;
				background: linear-gradient(270deg, rgba(112, 168, 64, 1) 0%, rgba(13, 126, 191, 1) 100%);
				.div2_left{
					float: left;
					margin-top: 33px;
					margin-left: 40px;
					width: 247px;
					height: 178px;
					.div2_left_p1{
						width: 100%;
						height: 34.91px;
						opacity: 1;
						/** 文本1 */
						font-size: 23.94px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 34.66px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
					.div2_left_p2{
						margin-top: 11px;
						width: 100%;
						height: 131.57px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(255, 255, 255, 1);
						text-align: left;
						vertical-align: top;
					}
				}
				.div2_right{
					float: left;
					margin-top: 86px;
					margin-left: 52px;
					width: 65px;
					height: 63px;
          .div2_right-img {
            width: 65px;
            height: 63px;
          }
				}
			}
			.div3{
				float: left;
				width: 439px;
				height: 180px;
				background: rgba(245, 248, 255, 1);
				.div3_left{
					float: left;
					margin-top: 33px;
					margin-left: 40px;
					width: 247px;
					height: 157px;
					.div3_left_p1{
						width: 100px;
						height: 34.91px;
						opacity: 1;
						/** 文本1 */
						font-size: 23.94px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 34.66px;
						color: rgba(51, 51, 51, 1);
						text-align: left;
						vertical-align: top;
					}
					.div3_left_p2{
						margin-top: 11px;
						width: 100%;
						height: 156.5px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(102, 102, 102, 1);
						text-align: left;
						vertical-align: top;
					}
				}
				.div3_right{
					float: left;
					margin-top: 96px;
					margin-left: 53px;
					width: 63px;
					height: 63px;
          .div3_right-img {
            width: 63px;
            height: 63px;
          }
				}
			}
		}
	}
}

</style>