<template>
	<div class="center">
		<Case/>
		<Deployment/>
		<Benefit/>
	</div>
</template>

<script>
import Case from "@/components/eBackup-case/case/index.vue";
import Deployment from "@/components/eBackup-case/deployment/index.vue";
import Benefit from "@/components/eBackup-case/benefit/index.vue";

export default {
	name: "index",
	components: {Benefit, Deployment, Case}
}
</script>

<style scoped>

</style>