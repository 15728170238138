<template>
      <div class="center">
            <tapeLibrary></tapeLibrary>
      </div>
</template>

<script>

import tapeLibrary from "@/components/tapeLibrary/index.vue";


export default {
      name: "index",
      components: { tapeLibrary }
}
</script>

<style scoped></style>