<template>
	<div class="cfile">
		<div class="cfile_wrap">
			<div class="cfile_wrap_top">
				<div class="cfile_wrap_top_wrap">
					<p class="cfile_wrap_top_wrap_top_p1">云归档盒Cfile</p>
					<p class="cfile_wrap_top_wrap_top_p2">
						云归档盒Cfile是专门为解决公有云S3对象存储协议与现有应用程序不兼容的问题而设计的，企业可以将本地数据归档和备份到S3对象存储，同时保留对本地文件系统的访问方式。这样，企业可以利用S3对象存储的海量存储空间和低成本优势，而无需对现有应用程序进行大规模的修改，为企业提供了一种平滑且高效的方式来进行公有云的迁移，实现了对S3对象存储的灵活应用。
					</p>
				</div>
			</div>
			<div class="cfile_wrap_botton">
				<img class="cfile-img" :src="cFile"/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Advantage",
	data(){
		return {
		  cFile: require('@/assets/cfile/Cfile.png')
		}
	},
	methods:{
	},
	created() {

	},
	computed:{
	},
	watch:{

	},

}
</script>

<style scoped>
.cfile{
  margin-top: 10%;
	width: 1920px;
	height: 831px;
	/* 子元素水平居中 */
	display: flex;
	justify-content: center;/* 水平居中 */
}
.cfile_wrap{
	width: 1182px;
}
.cfile_wrap_top{
	width: 100%;
	height: 233px;
	/* 子元素水平居中 */
	display: flex;
	justify-content: center;/* 水平居中 */
}
.botton{
	width: 1182px;
	height: 542px;
}
.cfile_wrap_top_wrap{

}
.cfile_wrap_top_wrap_top_p1{
	width: 100%;
	height: 47px;
	/** 文本1 */
	font-size: 32px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 46.34px;
	color: rgba(51, 51, 51, 1);
	text-align: center;
	vertical-align: top;
}
.cfile_wrap_top_wrap_top_p2{
	padding-top: 28px;
	width: 1082px;
	height: 87px;
	opacity: 1;
	/** 文本1 */
	font-size: 16px;
	font-weight: 300;
	letter-spacing: 0px;
	line-height: 23.17px;
	color: rgba(102, 102, 102, 1);
	text-align: center;
	vertical-align: top;
}
.cfile-img {
  width: 1182px;
  height: 542px;
}
</style>