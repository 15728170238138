<template>
  <div class="eMove_scenarios">
    <p class="eMove_scenarios_p">应用场景</p>
    <div class="eMove_scenarios_wrap">
      <div class="eMove_scenarios_content">
        <div v-on:mouseenter="tabOnmouseover('div1')" class="eMove_scenarios_content_div1" :class="{active: active == 'div1'}">
          <div class="eMove_scenarios_content_div1_background">
            <img class="eMove_scenarios_content_div1_background_img" :src="first"/>
          </div>
          <div class="eMove_scenarios_content_div1_wrap">
            <p class="eMove_scenarios_div1_wrap_p1">本地数据协同传统行业中小企业</p>
            <div class="eMove_scenarios_div1_wrap_div"></div>
            <p v-if="active == 'div1'" class="eMove_scenarios_div1_wrap_p2">客户本地数据存量小，对上云无急切需求，无专业运维人员</p>
            <p v-else class="eMove_scenarios_div1_wrap_p2">鼠标聚焦，查看介绍</p>
          </div>
        </div>
        <div v-on:mouseenter="tabOnmouseover('div2')" class="eMove_scenarios_content_div2" :class="{active: active == 'div2'}">
          <div class="eMove_scenarios_content_div2_background">
            <img class="eMove_scenarios_content_div2_background_img" :src="second"/>
          </div>
          <div class="eMove_scenarios_content_div2_wrap">
            <p class="eMove_scenarios_div2_wrap_p1">云端数据协同有多区域业务的公司等</p>
            <div class="eMove_scenarios_div2_wrap_div"></div>
            <p v-if="active == 'div2'" class="eMove_scenarios_div2_wrap_p2">多个地市开展业务，文件、数据库、操作系统等在各云平台都有业务数据</p>
            <p v-else class="eMove_scenarios_div2_wrap_p2">鼠标聚焦，查看介绍</p>
          </div>
        </div>
        <div v-on:mouseenter="tabOnmouseover('div3')" class="eMove_scenarios_content_div3" :class="{active: active == 'div3'}">
          <div class="eMove_scenarios_content_div3_background">
            <img class="eMove_scenarios_content_div3_background_img" :src="thired"/>
          </div>
          <div class="eMove_scenarios_content_div3_wrap">
            <p class="eMove_scenarios_div3_wrap_p1">本地和云端数据协同</p>
            <div class="eMove_scenarios_div3_wrap_div"></div>
            <p v-if="active == 'div3'" class="eMove_scenarios_div3_wrap_p2">客户数据存量大，本地备份成本过高，上云需求急切</p>
            <p v-else class="eMove_scenarios_div3_wrap_p2">鼠标聚焦，查看介绍</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  <div class="application">
      <p class="application_top">应用场景</p>
      <div class="application_wrap">
        <div class="application_wrap_botton">
          <div class="localSync">
            <img class="localSync-img" :src="localSync">
            <div class="localSync-text">
              <div class="localSync-text_title">
                本地数据协同传统行业中小企业
              </div>
              <div class="localSync_divider"></div>
              <div class="localSync_desc">
                客户本地数据存量小，对上云无急切需求，无专业运维人员
              </div>
            </div>
          </div>
          <div class="manyArea">
            <img class="manyArea-img" :src="manyArea">
            <div class="manyArea-text">
              <div class="manyArea_title">
                云端数据协同有多区域业务的公司等
              </div>
              <div class="manyArea_divider"></div>
            </div>
          </div>
          <div class="cloudSync">
            <img class="cloudSync-img" :src="cloudSync">
            <div class="cloudSync-text">
              <div class="cloudSync_title">
                本地和云端数据协同
              </div>
              <div class="cloudSync_divider"></div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
</template>

<script>
export default {
  name: "Application",
  data() {
    return {
      first: require('@/assets/eBackup/application/localSync.png'),
      second: require('@/assets/eBackup/application/manyArea.png'),
      thired: require('@/assets/eBackup/application/cloudSync.png'),
      active: 'div1',
    }
  },
  methods: {
    tabOnmouseover(name) {
      this.active = name
    }
  }
}
</script>

<style lang="scss" scoped>
.active {
  transition: width 1.3s;
  width: 695px !important;
}


.eMove_scenarios {
  width: 1920px;
  height: 731px;
  opacity: 1;
  background: rgba(245, 248, 255, 1);

  .eMove_scenarios_p {
    padding-top: 69px;
    width: 100%;
    height: 47px;
    opacity: 1;
    /** 文本1 */
    font-size: 31.92px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 46.22px;
    color: rgba(34, 34, 34, 1);
    text-align: center;
    vertical-align: top;
  }

  .eMove_scenarios_wrap {
    width: 1920px;
    height: 501px;
    display: flex;
    justify-content: center;

    .eMove_scenarios_content {
      margin-top: 33px;
      margin-left: 250px;
      width: 1920px;
      height: 501px;

      .eMove_scenarios_content_div1 {
        float: left;
        margin-right: 1px;
        position: relative;
        width: 349px;
        height: 501px;

        .eMove_scenarios_content_div1_background {
          position: absolute;
          width: 695px;
          height: 501px;

          .eMove_scenarios_content_div1_background_img {
            width: 695px;
            height: 501px;
          }
        }

        .eMove_scenarios_content_div1_wrap {
          position: absolute;
          width: 695px;
          height: 501px;

          .eMove_scenarios_div1_wrap_p1 {
            margin-top: 390px;
            margin-left: 41px;
            width: 100%;
            height: 23.94px;
            opacity: 1;
            /** 文本1 */
            font-size: 15.96px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 23.11px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
          }

          .eMove_scenarios_div1_wrap_div {
            margin-top: 6px;
            margin-left: 42px;
            width: 45.88px;
            height: 5.98px;
            opacity: 1;
            border-radius: 2.99px;
            background: rgba(255, 255, 255, 1);
          }

          .eMove_scenarios_div1_wrap_p2 {
            margin-top: 17px;
            margin-left: 40px;
            width: 100%;
            height: 20.95px;
            opacity: 1;
            /** 文本1 */
            font-size: 13.96px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20.22px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
          }
        }
      }

      .eMove_scenarios_content_div2 {
        float: left;
        margin-right: 1px;
        position: relative;
        width: 349px;
        height: 501px;

        .eMove_scenarios_content_div2_background {
          position: absolute;
          width: 100%;
          height: 501px;

          .eMove_scenarios_content_div2_background_img {
            width: 100%;
            height: 501px;
          }
        }

        .eMove_scenarios_content_div2_wrap {
          position: absolute;
          width: 100%;
          height: 501px;

          .eMove_scenarios_div2_wrap_p1 {
            margin-top: 390px;
            margin-left: 41px;
            width: 100%;
            height: 23.94px;
            opacity: 1;
            /** 文本1 */
            font-size: 15.96px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 23.11px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
          }

          .eMove_scenarios_div2_wrap_div {
            margin-top: 7px;
            margin-left: 42px;
            width: 45.88px;
            height: 5.98px;
            opacity: 1;
            border-radius: 2.99px;
            background: rgba(255, 255, 255, 1);
          }

          .eMove_scenarios_div2_wrap_p2 {
            margin-top: 17px;
            margin-left: 40px;
            width: 100%;
            height: 20.95px;
            opacity: 1;
            /** 文本1 */
            font-size: 13.96px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20.22px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
          }
        }
      }

      .eMove_scenarios_content_div3 {
        float: left;
        position: relative;
        width: 349px;
        height: 501px;

        .eMove_scenarios_content_div3_background {
          position: absolute;
          width: 100%;
          height: 501px;

          .eMove_scenarios_content_div3_background_img {
            width: 100%;
            height: 501px;
          }
        }

        .eMove_scenarios_content_div3_wrap {
          position: absolute;
          width: 100%;
          height: 501px;

          .eMove_scenarios_div3_wrap_p1 {
            margin-top: 390px;
            margin-left: 41px;
            width: 100%;
            height: 23.94px;
            opacity: 1;
            /** 文本1 */
            font-size: 15.96px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 23.11px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
          }

          .eMove_scenarios_div3_wrap_div {
            margin-top: 7px;
            margin-left: 42px;
            width: 45.88px;
            height: 5.98px;
            opacity: 1;
            border-radius: 2.99px;
            background: rgba(255, 255, 255, 1);
          }

          .eMove_scenarios_div3_wrap_p2 {
            margin-top: 17px;
            margin-left: 40px;
            width: 100%;
            height: 20.95px;
            opacity: 1;
            /** 文本1 */
            font-size: 13.96px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 20.22px;
            color: rgba(255, 255, 255, 1);
            text-align: left;
            vertical-align: top;
          }
        }
      }
    }
  }
}


.application {
  width: 100%;
  height: 733px;
  background: rgba(245, 248, 255, 1);
}

.application_top {
  padding-top: 69px;
  width: 100%;
  height: 47px;
  opacity: 1;
  /** 文本1 */
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 46.34px;
  color: rgba(34, 34, 34, 1);
  text-align: center;
  vertical-align: top;
}

.application_wrap {
  /* 子元素水平居中 */
  display: flex;
  justify-content: center;
}

.application_wrap_botton {
  width: 100%;
  margin-top: 30px;
  text-align: left;

  .localSync {
    margin-left: 14%;
    position: relative;

    .localSync-img {
      position: absolute;
    }

    .localSync-text {
      position: absolute;
      margin-left: 3%;
      margin-top: 25%;

      .localSync-text_title {
        /** 文本1 */
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        vertical-align: top;
      }

      .localSync_divider {
        margin-top: 2%;
        width: 46px;
        height: 6px;
        opacity: 1;
        border-radius: 3px;
        background: rgba(255, 255, 255, 1);
      }

      .localSync_desc {
        margin-top: 2%;
        /** 文本1 */
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 20.27px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        vertical-align: top;

      }
    }
  }

  .manyArea {
    margin-left: 50.5%;
    position: relative;

    .manyArea-img {
      position: absolute;
    }

    .manyArea-text {
      position: absolute;
      margin-left: 4%;
      margin-top: 44%;

      .manyArea_title {
        /** 文本1 */
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        vertical-align: top;
      }

      .manyArea_divider {
        margin-top: 2%;
        width: 46px;
        height: 6px;
        opacity: 1;
        border-radius: 3px;
        background: rgba(255, 255, 255, 1);
      }
    }
  }

  .cloudSync {
    margin-left: 69%;
    position: relative;

    .cloudSync-img {
      position: absolute;
    }

    .cloudSync-text {
      position: absolute;
      margin-left: 5%;
      margin-top: 70%;

      .cloudSync_title {
        /** 文本1 */
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: rgba(255, 255, 255, 1);
        text-align: left;
        vertical-align: top;
      }

      .cloudSync_divider {
        margin-top: 3%;
        width: 46px;
        height: 6px;
        opacity: 1;
        border-radius: 3px;
        background: rgba(255, 255, 255, 1);
      }
    }
  }
}
</style>