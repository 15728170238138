<template>
	<div class="center">
		<OneMachine/>
	</div>
</template>

<script>
import OneMachine from "@/components/oneMachine/index.vue";

export default {
	name: "index",
	components: {OneMachine}
}
</script>

<style scoped>

</style>