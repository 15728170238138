<template>
	<div class="center">
		<EManageChallenge/>
		<EManageStructure/>
		<EManageAdvantage/>
		<EManageValue/>
	</div>
</template>

<script>
import EManageChallenge from "@/components/eManage/eManage-challenge/index.vue";
import EManageStructure from "@/components/eManage/eManage-structure/index.vue";
import EManageAdvantage from "@/components/eManage/eManage-advantage/index.vue";
import EManageValue from "@/components/eManage/eManage-value/index.vue";

export default {
	name: "index",
	components: {EManageValue, EManageAdvantage, EManageStructure, EManageChallenge}
}
</script>

<style scoped>

</style>