<template>
	<div class="eMove_function">
		<div class="eMove_function_background">
			<img class="eMove_function_background_img" :src="background"/>
		</div>
		<div class="eMove_function_wrap">
			<div class="eMove_function_content">
				<p class="eMove_function_content_p">功能</p>
				<div class="eMove_function_content_div">
					<div class="content_left">
						<div class="content_left_wrap">
							<div class="content_left_wrap_div_img">
								<img class="login-icon" :src="denglu"/>
							</div>
							<p class="content_left_wrap_p1">一键登录</p>
							<p class="content_left_wrap_p2">支持账户登录或验证码方式登录，端对端确认后连接，保障用户安全性</p>
						</div>
					</div>
					<div class="content_right">
						<div class="content_top">
							<div class="content_top_left">
								<div class="content_top_left_wrap">
									<div class="content_top_left_img">
										<img class="auto-icon" :src="shipai"/>
									</div>
									<p class="content_top_left_p1">自动适配</p>
									<p class="content_top_left_p2">软件适配主流windows桌面版和服务器版操作系统，确保迁移过程的兼容性</p>
								</div>
							</div>
							<div class="content_top_right">
								<div class="content_top_right_wrap">
									<div class="content_top_right_img">
										<img class="safe-icon" :src="anquan"/>
									</div>
									<p class="content_top_right_p1">数据安全</p>
									<p class="content_top_right_p2">在迁移过程中，软件会对用户自定义认证加密，确保数据的安全性和隐私性</p>
								</div>
							</div>
						</div>
						<div class="content_botton">
							<div class="content_botton_left">
								<div class="content_botton_left_wrap">
									<div class="content_botton_left_img">
										<img class="point-icon" :src="duandian"/>
									</div>
									<p class="content_botton_left_p1">断点传输</p>
									<p class="content_botton_left_p2">支持迁移过程断点续传，保障搬家稳定性</p>
								</div>
							</div>
							<div class="content_botton_right">
								<div class="content_botton_right_wrap">
									<div class="content_botton_right_img">
										<img class="monitor-icon" :src="jiankong"/>
									</div>
									<p class="content_botton_right_p1">迁移监控</p>
									<p class="content_botton_right_p2">软件提供实时监控和反馈功能，用户可以随时了解迁移的进度和状态</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EMoveFunction",
	data(){
		return {
			background: require('@/assets/eMove/eMove-function/background.png'),
			denglu: require('@/assets/eMove/eMove-function/5.png'),
			shipai: require('@/assets/eMove/eMove-function/4.png'),
			anquan: require('@/assets/eMove/eMove-function/3.png'),
			duandian: require('@/assets/eMove/eMove-function/2.png'),
			jiankong: require('@/assets/eMove/eMove-function/1.png'),
		}
	},
	methods:{
	},
	created() {

	},
	computed:{
	},
	watch:{

	},

}
</script>

<style lang="scss" scoped>
.eMove_function{
	position: relative;
	width: 1920px;
	height: 892px;
	.eMove_function_background{
		position: absolute;
		width: 100%;
		height: 892px;
		.eMove_function_background_img{
			width: 100%;
			height: 892px;
		}
	}
	.eMove_function_wrap{
		position: absolute;
		width: 100%;
		height: 892px;
		/* 子元素居中 */
		display: flex;
		justify-content: center; /* 子元素水平居中 */
		.eMove_function_content{
			width: 1397px;
			height: 810px;
			.eMove_function_content_p{
				margin-top: 70px;
				width: 100%;
				height: 47px;
				opacity: 1;
				/** 文本1 */
				font-size: 31.92px;
				font-weight: 700;
				letter-spacing: 0;
				line-height: 46.22px;
				color: rgba(51, 51, 51, 1);
				text-align: center;
				vertical-align: top;
			}
			.eMove_function_content_div{
				margin-top: 55px;
				width: 1397px;
				height: 638px;
				.content_left{
					float: left;
					display: flex;
					justify-content: center;
					margin-right: 40px;
					width: 439px;
					height: 638px;
					background: rgba(255, 255, 255, 1);
					box-shadow: 0px 34.91px 75.8px  rgba(0, 0, 0, 0.06);
					.content_left_wrap{
						margin-top: 184px;
						width: 344px;
						height: 294px;
						.content_left_wrap_div_img{
							width: 100%;
							height: 55px;
							display: flex;
							justify-content: center;
              .login-icon{
                width: 68px;
                height: 55px;
              }
						}
						.content_left_wrap_p1{
							margin-top: 54px;
							width: 100%;
							height: 31.92px;
							opacity: 1;
							/** 文本1 */
							font-size: 21.94px;
							font-weight: 400;
							letter-spacing: 0;
							line-height: 31.78px;
							color: rgba(51, 51, 51, 1);
							text-align: center;
							vertical-align: top;
						}
						.content_left_wrap_p2{
							margin-top: 19px;
							width: 100%;
							height: 133.98px;
							opacity: 1;
							/** 文本1 */
							font-size: 15.96px;
							font-weight: 300;
							letter-spacing: 0;
							line-height: 23.11px;
							color: rgba(102, 102, 102, 1);
							text-align: center;
							vertical-align: top;
						}
					}
				}
				.content_right{
					float: left;
					width: 918px;
					height: 638px;
					.content_top{
						margin-bottom: 40px;
						width: 918px;
						height: 299px;
						.content_top_left{
							float: left;
							margin-right: 40px;
							width: 439px;
							height: 299px;
							background: rgba(255, 255, 255, 1);
							box-shadow: 0px 34.91px 75.8px  rgba(0, 0, 0, 0.06);
							display: flex;
							justify-content: center;
							.content_top_left_wrap{
								margin-top: 53px;
								width: 357px;
								height: 247px;
								.content_top_left_img{
									width: 100%;
									height: 66px;
                  .auto-icon {
                    width: 68px;
                    height: 55px;
                  }
								}
								.content_top_left_p1{
									margin-top: 24px;
									width: 100%;
									height: 31.92px;
									opacity: 1;
									/** 文本1 */
									font-size: 21.94px;
									font-weight: 400;
									letter-spacing: 0;
									line-height: 31.78px;
									color: rgba(51, 51, 51, 1);
									text-align: center;
									vertical-align: top;
								}
								.content_top_left_p2{
									margin-top: 19px;
									width: 100%;
									height: 106px;
									opacity: 1;
									/** 文本1 */
									font-size: 15.96px;
									font-weight: 300;
									letter-spacing: 0;
									line-height: 23.11px;
									color: rgba(102, 102, 102, 1);
									text-align: center;
									vertical-align: top;
								}
							}

						}
						.content_top_right{
							float: left;
							width: 439px;
							height: 299px;
							background: rgba(255, 255, 255, 1);
							box-shadow: 0px 34.91px 75.8px  rgba(0, 0, 0, 0.06);
							display: flex;
							justify-content: center;
							.content_top_right_wrap{
								margin-top: 48px;
								width: 363px;
								height: 252px;
								.content_top_right_img{
									width: 100%;
									height: 74px;
                  .safe-icon {
                    width: 68px;
                    height: 55px;
                  }
								}
								.content_top_right_p1{
									margin-top: 21px;
									width: 100%;
									height: 31.92px;
									opacity: 1;
									/** 文本1 */
									font-size: 21.94px;
									font-weight: 400;
									letter-spacing: 0px;
									line-height: 31.78px;
									color: rgba(51, 51, 51, 1);
									text-align: center;
									vertical-align: top;
								}
								.content_top_right_p2{
									margin-top: 19px;
									width: 100%;
									height: 106px;
									opacity: 1;
									/** 文本1 */
									font-size: 15.96px;
									font-weight: 300;
									letter-spacing: 0;
									line-height: 23.11px;
									color: rgba(102, 102, 102, 1);
									text-align: center;
									vertical-align: top;
								}
							}
						}
					}
					.content_botton{
						width: 918px;
						height: 299px;
						.content_botton_left{
							float: left;
							margin-right: 40px;
							width: 439px;
							height: 299px;
							background: rgba(255, 255, 255, 1);
							box-shadow: 0px 34.91px 75.8px  rgba(0, 0, 0, 0.06);
							display: flex;
							justify-content: center;
							.content_botton_left_wrap{
								margin-top: 51px;
								width: 357px;
								height: 240px;
								.content_botton_left_img{
									width: 100%;
									height: 69px;
                  .point-icon {
                    width: 68px;
                    height: 55px;
                  }
								}
								.content_botton_left_p1{
									margin-top: 23px;
									width: 100%;
									height: 31.92px;
									opacity: 1;
									/** 文本1 */
									font-size: 21.94px;
									font-weight: 400;
									letter-spacing: 0;
									line-height: 31.78px;
									color: rgba(51, 51, 51, 1);
									text-align: center;
									vertical-align: top;
								}
								.content_botton_left_p2{
									margin-top: 19px;
									width: 100%;
									height: 97.08px;
									opacity: 1;
									/** 文本1 */
									font-size: 15.96px;
									font-weight: 300;
									letter-spacing: 0;
									line-height: 23.11px;
									color: rgba(102, 102, 102, 1);
									text-align: center;
									vertical-align: top;
								}
							}
						}
						.content_botton_right{
							float: left;
							width: 439px;
							height: 299px;
							background: rgba(255, 255, 255, 1);
							box-shadow: 0px 34.91px 75.8px  rgba(0, 0, 0, 0.06);
							display: flex;
							justify-content: center;
							.content_botton_right_wrap{
								margin-top: 51px;
								width: 351px;
								height: 217px;
								.content_botton_right_img{
									width: 100%;
									height: 57px;
                  .monitor-icon {
                    width: 68px;
                    height: 55px;
                  }
								}
								.content_botton_right_p1{
									margin-top: 31px;
									width: 100%;
									height: 31.92px;
									opacity: 1;
									/** 文本1 */
									font-size: 21.94px;
									font-weight: 400;
									letter-spacing: 0;
									line-height: 31.78px;
									color: rgba(51, 51, 51, 1);
									text-align: center;
									vertical-align: top;
								}
								.content_botton_right_p2{
									margin-top: 19px;
									width: 100%;
									height: 77.63px;
									opacity: 1;
									/** 文本1 */
									font-size: 15.96px;
									font-weight: 300;
									letter-spacing: 0;
									line-height: 23.11px;
									color: rgba(102, 102, 102, 1);
									text-align: center;
									vertical-align: top;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>