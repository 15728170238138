<template>
      <div>
            <div class="eManage_challenge">
                  <p class="eManage_challenge_p">痛点/挑战</p>
                  <div class="eMove_introduction_wrap">
                        <div class="eMove_introduction_div">
                              <p class="eMove_introduction_div_p">
                                    随着服务器数量、数据量和高价值业务系统的爆炸式增长，用户对业务连续性的要求也急剧提高，传统的备份与恢复方法已难以满足要求，这些问题都成为IT建设的严重障碍和IT维护管理的难题。
                              </p>
                        </div>
                  </div>
                  <div class="eManage_challenge_wrap">
                        <div class="eManage_challenge_content">
                              <div class="eManage_challenge_content_div1">
                                    <div class="eManage_challenge_div1_wrap">
                                          <img class="eManage_challenge_div1_img" :src="fengxian" />
                                          <div class="eManage_challenge_div1_right">
                                                <p class="eManage_challenge_div1_right_p1">备份时间太长、恢复速度太慢</p>
                                          </div>
                                    </div>
                              </div>
                              <div class="eManage_challenge_content_div2">
                                    <div class="eManage_challenge_div2_wrap">
                                          <img class="eManage_challenge_div2_img" :src="xiaolv" />
                                          <div class="eManage_challenge_div2_right">
                                                <p class="eManage_challenge_div2_right_p1">验证太麻烦、管理太复杂</p>
                                          </div>
                                    </div>
                              </div>
                              <div class="eManage_challenge_content_div3">
                                    <div class="eManage_challenge_div3_wrap">
                                          <img class="eManage_challenge_div3_img" :src="yunying" />
                                          <div class="eManage_challenge_div3_right">
                                                <p class="eManage_challenge_div3_right_p1">设备故障率高、远程备份费用高昂</p>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
            <div class="eManage_challenge" style="height: 230px;">
                  <p class="eManage_challenge_p" style="padding-top: 2%;">简介</p>
                  <div class="eMove_introduction_wrap">
                        <div class="eMove_introduction_div">
                              <p class="eMove_introduction_div_p">
                                    LinkedStor凭借全新的理念和先进的技术完全消除这些问题，轻松实现实时在线备份和备份数据瞬时取回，不仅能大幅提升服务水平（SLA）、降低恢复点和恢复时间目标(RPO/RTO)的，而且管理方便、功能全面，对于企业而言，是一套具有极高投资回率和极低总体拥有成本的解决方案。
                              </p>
                        </div>
                  </div>
            </div>
            <div class="eMove_function">
                  <div class="eMove_function_background">
                        <img class="eMove_function_background_img" :src="background" />
                  </div>
                  <div class="eMove_function_wrap">
                        <div class="eMove_function_content">
                              <p class="eMove_function_content_p">核心优势</p>
                              <div class="eMove_function_content_div">
                                    <div class="content_left">
                                          <div class="content_left_wrap">
                                                <div class="content_left_wrap_div_img">
                                                      <img class="login-icon" :src="denglu" />
                                                </div>
                                                <p class="content_left_wrap_p1">功能全面</p>
                                                <p class="content_left_wrap_p2" style="text-align: start;">
                                                      • 提供实时/定时的数据备份能力<br>
                                                      • 广泛支持Windows/Linux/麒麟/统信等操作系统上的众多应用程序<br>
                                                      • 支持操作系统备份及裸机恢复<br>
                                                      • 支持一键式自动切换/回切<br>
                                                      • 瞬时完成备份，支持大于256份恢复点<br>
                                                      • 支持备份版本循环回收策略，多种备份等级供用户自行定义<br>
                                                      • 支持IO级持续数据保护，可恢复任意一个IO时间的数据<br>
                                                      • 支持磁盘级备份与文件级备份的客户端<br>
                                                      • 支持远程复制功能，轻松实现远程归档备份</p>
                                          </div>
                                    </div>
                                    <div class="content_right">
                                          <div class="content_top">
                                                <div class="content_top_left">
                                                      <div class="content_top_left_wrap">
                                                            <div class="content_top_left_img">
                                                                  <img class="auto-icon" :src="shipai" />
                                                            </div>
                                                            <p class="content_top_left_p1">配置简单，扩展方便</p>
                                                            <p class="content_top_left_p2" style="text-align: start;">
                                                                  • 配置简单，即插即用，5分钟完成安装配置<br>
                                                                  • 可扩容设计，最大支持扩容至PB可用容量
                                                            </p>
                                                      </div>
                                                </div>
                                                <div class="content_top_right">
                                                      <div class="content_top_right_wrap">
                                                            <div class="content_top_right_img">
                                                                  <img class="safe-icon" :src="anquan" />
                                                            </div>
                                                            <p class="content_top_right_p1">高性能设计</p>
                                                            <p class="content_top_right_p2" style="text-align: start;">
                                                                  • 可使用PCIe SSD作为高速缓存，有效防止掉电丢失缓存<br>
                                                                  • 可使用PCIe SSD作为高速存储卷，适配核心应用系统性能要求<br>
                                                                  • 读写性能>10~100万IOPS（不同块及读写比例）<br>
                                                                  • 对随机数据和顺序数据读取都能加速处理</p>
                                                      </div>
                                                </div>
                                          </div>
                                          <div class="content_top">
                                                <div class="content_top_left">
                                                      <div class="content_top_left_wrap">
                                                            <div class="content_top_left_img">
                                                                  <img class="auto-icon" :src="shipai" />
                                                            </div>
                                                            <p class="content_top_left_p1">全面支持异构环境</p>
                                                            <p class="content_top_left_p2" style="text-align: start;">
                                                                  • 支持物理服务器和虚拟服务器<br>
                                                                  • 支持Windows、Linux、麒麟、统信等<br>
                                                                  • 广泛支持Windows/Linux/麒麟/统信等上的众多应用程序<br>
                                                                  • 支持Thin Provisioning 精简配置<br>
                                                                  • 同时支持干路和旁路方式接入用户生产系统。
                                                            </p>
                                                      </div>
                                                </div>
                                                <div class="content_top_right">
                                                      <div class="content_top_right_wrap">
                                                            <div class="content_top_right_img">
                                                                  <img class="safe-icon" :src="anquan" />
                                                            </div>
                                                            <p class="content_top_right_p1">基于磁盘的备份</p>
                                                            <p class="content_top_right_p2" style="text-align: start;">
                                                                  • 实时在线备份，消除备份窗口<br>
                                                                  • 恢复点目标 (RPO) 接近于零<br>
                                                                  •灵活的保护策略，覆盖从单个服务器、到部门到全局应用需求</p>
                                                      </div>
                                                </div>
                                          </div>
                                          <div class="content_botton">
                                                <div class="content_botton_left">
                                                      <div class="content_botton_left_wrap">
                                                            <div class="content_botton_left_img">
                                                                  <img class="point-icon" :src="duandian" />
                                                            </div>
                                                            <p class="content_botton_left_p1">提供自动化灾难恢复工具</p>
                                                            <p class="content_botton_left_p2"
                                                                  style="text-align: start;">
                                                                  • 自动完成复杂应用程序的恢复和灾难恢复演练测试<br>
                                                                  • 提供快速故障切换和灾难恢复<br>
                                                                  • 支持同型号或不同型号间的物理机恢复（P2P）<br>
                                                                  • 支持同种或异种虚拟机平台间的恢复（V2V）<br>
                                                                  • 支持从物理机恢复到虚拟机（P2V）<br>
                                                                  • 支持从虚拟机恢复到物理机（V2P）<br>
                                                                  • 支持各主流存储设备</p>
                                                      </div>
                                                </div>
                                                <div class="content_botton_right">
                                                      <div class="content_botton_right_wrap">
                                                            <div class="content_botton_right_img">
                                                                  <img class="monitor-icon" :src="jiankong" />
                                                            </div>
                                                            <p class="content_botton_right_p1">WAN优化复制</p>
                                                            <p class="content_botton_right_p2" style="text-align: start;">
                                                                  • 带宽需求降低高达95%，极大节约成本<br>
                                                                  • 压缩传输与差异扫描技术降低带宽需求及成本<br>
                                                                  • 多种传输模式降低带宽要求</p>
                                                      </div>
                                                </div>
                                          </div>

                                    </div>
                              </div>
                        </div>
                  </div>
            </div>
      </div>
</template>

<script>
export default {
      name: "EManageChallenge",
      data() {
            return {
                  fengxian: require('@/assets/eManage/eManage_challenge/3.png'),
                  xiaolv: require('@/assets/eManage/eManage_challenge/2.png'),
                  yunying: require('@/assets/eManage/eManage_challenge/1.png'),
                  background: require('@/assets/eMove/eMove-function/background.png'),
                  denglu: require('@/assets/eMove/eMove-function/5.png'),
                  shipai: require('@/assets/eMove/eMove-function/4.png'),
                  anquan: require('@/assets/eMove/eMove-function/3.png'),
                  duandian: require('@/assets/eMove/eMove-function/2.png'),
                  jiankong: require('@/assets/eMove/eMove-function/1.png'),
            }
      },
      methods: {},
      created() { },
      computed: {},
      watch: {},
}
</script>

<style lang="scss" scoped>
.eMove_introduction_wrap {
      margin-top: 29px;
      width: 100%;
      height: 78px;
      /* 子元素居中 */
      display: flex;
      justify-content: center;

      /* 子元素水平居中 */
      .eMove_introduction_div {
            width: 941.55px;
            height: 77.52px;

            .eMove_introduction_div_p {
                  width: 941.55px;
                  height: 77.52px;
                  opacity: 1;
                  /** 文本1 */
                  font-size: 15.96px;
                  font-weight: 300;
                  letter-spacing: 0;
                  line-height: 23.11px;
                  color: rgba(102, 102, 102, 1);
                  text-align: center;
                  vertical-align: top;
            }
      }
}

.eManage_challenge {
      width: 1920px;
      height: 620px;

      .eManage_challenge_p {
            padding-top: 9%;
            width: 1920px;
            height: 47px;
            opacity: 1;
            /** 文本1 */
            font-size: 31.92px;
            font-weight: 700;
            letter-spacing: 0;
            line-height: 47px;
            color: rgba(34, 34, 34, 1);
            text-align: center;
            vertical-align: top;
      }

      .eManage_challenge_wrap {
            margin-top: 35px;
            width: 1920px;
            height: 199px;
            display: flex;
            justify-content: center;

            .eManage_challenge_content {
                  width: 1397px;
                  height: 199px;

                  .eManage_challenge_content_div1 {
                        float: left;
                        margin-right: 40px;
                        width: 439px;
                        height: 199px;
                        background: rgba(245, 248, 255, 1);

                        .eManage_challenge_div1_wrap {
                              margin-top: 53px;
                              margin-left: 41px;
                              width: 345px;
                              height: 139px;

                              .eManage_challenge_div1_img {
                                    float: left;
                                    margin-top: 8px;
                                    width: 62px;
                                    height: 70px;
                              }

                              .eManage_challenge_div1_right {
                                    float: left;
                                    margin-left: 27px;
                                    width: 256px;
                                    height: 139px;

                                    .eManage_challenge_div1_right_p1 {
                                          width: 100%;
                                          height: 35px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 23.94px;
                                          font-weight: 400;
                                          letter-spacing: 0;
                                          line-height: 34.66px;
                                          color: rgba(51, 51, 51, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }

                                    .eManage_challenge_div1_right_p2 {
                                          margin-top: 10px;
                                          width: 255.83px;
                                          height: 94.08px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 15.96px;
                                          font-weight: 300;
                                          letter-spacing: 0;
                                          line-height: 23.11px;
                                          color: rgba(102, 102, 102, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }
                              }
                        }
                  }

                  .eManage_challenge_content_div2 {
                        float: left;
                        margin-right: 40px;
                        width: 439px;
                        height: 199px;
                        background: rgba(245, 248, 255, 1);

                        .eManage_challenge_div2_wrap {
                              margin-top: 53px;
                              margin-left: 35px;
                              width: 362px;
                              height: 131px;

                              .eManage_challenge_div2_img {
                                    float: left;
                                    margin-top: 1px;
                                    width: 56px;
                                    height: 70px;
                              }

                              .eManage_challenge_div2_right {
                                    float: left;
                                    margin-left: 28px;
                                    width: 278px;
                                    height: 131px;

                                    .eManage_challenge_div2_right_p1 {
                                          width: 100%;
                                          height: 35px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 23.94px;
                                          font-weight: 400;
                                          letter-spacing: 0;
                                          line-height: 34.66px;
                                          color: rgba(51, 51, 51, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }

                                    .eManage_challenge_div2_right_p2 {
                                          margin-top: 10px;
                                          width: 277.78px;
                                          height: 86.11px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 15.96px;
                                          font-weight: 300;
                                          letter-spacing: 0;
                                          line-height: 23.11px;
                                          color: rgba(102, 102, 102, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }
                              }
                        }
                  }

                  .eManage_challenge_content_div3 {
                        float: left;
                        width: 439px;
                        height: 199px;
                        background: rgba(245, 248, 255, 1);

                        .eManage_challenge_div3_wrap {
                              margin-top: 53px;
                              margin-left: 41px;
                              width: 345px;
                              height: 133px;

                              .eManage_challenge_div3_img {
                                    float: left;
                                    margin-top: 3px;
                                    width: 55px;
                                    height: 64px;
                              }

                              .eManage_challenge_div3_right {
                                    float: left;
                                    margin-left: 34px;
                                    width: 256px;
                                    height: 133px;

                                    .eManage_challenge_div3_right_p1 {
                                          width: 100%;
                                          height: 34.91px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 23.94px;
                                          font-weight: 400;
                                          letter-spacing: 0;
                                          line-height: 34.66px;
                                          color: rgba(51, 51, 51, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }

                                    .eManage_challenge_div3_right_p2 {
                                          margin-top: 10px;
                                          width: 255.83px;
                                          height: 88.1px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 15.96px;
                                          font-weight: 300;
                                          letter-spacing: 0;
                                          line-height: 23.11px;
                                          color: rgba(102, 102, 102, 1);
                                          text-align: left;
                                          vertical-align: top;
                                    }
                              }
                        }
                  }
            }
      }
}

.eMove_function {
      position: relative;
      width: 1920px;
      height: 1550px;

      .eMove_function_background {
            position: absolute;
            width: 100%;
            height: 892px;

            .eMove_function_background_img {
                  width: 100%;
                  height: 1550px;

            }
      }

      .eMove_function_wrap {
            position: absolute;
            width: 100%;
            height: 892px;
            /* 子元素居中 */
            display: flex;
            justify-content: center;

            /* 子元素水平居中 */
            .eMove_function_content {
                  width: 1397px;
                  height: 810px;

                  .eMove_function_content_p {
                        margin-top: 70px;
                        width: 100%;
                        height: 47px;
                        opacity: 1;
                        /** 文本1 */
                        font-size: 31.92px;
                        font-weight: 700;
                        letter-spacing: 0;
                        line-height: 46.22px;
                        color: rgba(51, 51, 51, 1);
                        text-align: center;
                        vertical-align: top;
                  }

                  .eMove_function_content_div {
                        margin-top: 55px;
                        width: 1397px;
                        height: 638px;

                        .content_left {
                              float: left;
                              display: flex;
                              justify-content: center;
                              margin-right: 40px;
                              width: 439px;
                              height: 1280px;
                              background: rgba(255, 255, 255, 1);
                              box-shadow: 0px 34.91px 75.8px rgba(0, 0, 0, 0.06);

                              .content_left_wrap {
                                    margin-top: 184px;
                                    width: 344px;
                                    height: 294px;

                                    .content_left_wrap_div_img {
                                          width: 100%;
                                          height: 55px;
                                          display: flex;
                                          justify-content: center;

                                          .login-icon {
                                                width: 68px;
                                                height: 55px;
                                          }
                                    }

                                    .content_left_wrap_p1 {
                                          margin-top: 54px;
                                          width: 100%;
                                          height: 31.92px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 21.94px;
                                          font-weight: 400;
                                          letter-spacing: 0;
                                          line-height: 31.78px;
                                          color: rgba(51, 51, 51, 1);
                                          text-align: center;
                                          vertical-align: top;
                                    }

                                    .content_left_wrap_p2 {
                                          margin-top: 19px;
                                          width: 100%;
                                          height: 133.98px;
                                          opacity: 1;
                                          /** 文本1 */
                                          font-size: 15.96px;
                                          font-weight: 300;
                                          letter-spacing: 0;
                                          line-height: 23.11px;
                                          color: rgba(102, 102, 102, 1);
                                          text-align: center;
                                          vertical-align: top;
                                    }
                              }
                        }

                        .content_right {
                              float: left;
                              width: 918px;
                              height: 638px;

                              .content_top {
                                    margin-bottom: 40px;
                                    width: 918px;
                                    height: 400px;

                                    .content_top_left {
                                          float: left;
                                          margin-right: 40px;
                                          width: 439px;
                                          height: 400px;
                                          background: rgba(255, 255, 255, 1);
                                          box-shadow: 0px 34.91px 75.8px rgba(0, 0, 0, 0.06);
                                          display: flex;
                                          justify-content: center;

                                          .content_top_left_wrap {
                                                margin-top: 53px;
                                                width: 357px;
                                                height: 247px;

                                                .content_top_left_img {
                                                      width: 100%;
                                                      height: 66px;

                                                      .auto-icon {
                                                            width: 68px;
                                                            height: 55px;
                                                      }
                                                }

                                                .content_top_left_p1 {
                                                      margin-top: 24px;
                                                      width: 100%;
                                                      height: 31.92px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 21.94px;
                                                      font-weight: 400;
                                                      letter-spacing: 0;
                                                      line-height: 31.78px;
                                                      color: rgba(51, 51, 51, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }

                                                .content_top_left_p2 {
                                                      margin-top: 19px;
                                                      width: 100%;
                                                      height: 106px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 15.96px;
                                                      font-weight: 300;
                                                      letter-spacing: 0;
                                                      line-height: 23.11px;
                                                      color: rgba(102, 102, 102, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }
                                          }

                                    }

                                    .content_top_right {
                                          float: left;
                                          width: 439px;
                                          height:400px;
                                          background: rgba(255, 255, 255, 1);
                                          box-shadow: 0px 34.91px 75.8px rgba(0, 0, 0, 0.06);
                                          display: flex;
                                          justify-content: center;

                                          .content_top_right_wrap {
                                                margin-top: 48px;
                                                width: 363px;
                                                height: 252px;

                                                .content_top_right_img {
                                                      width: 100%;
                                                      height: 74px;

                                                      .safe-icon {
                                                            width: 68px;
                                                            height: 55px;
                                                      }
                                                }

                                                .content_top_right_p1 {
                                                      margin-top: 21px;
                                                      width: 100%;
                                                      height: 31.92px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 21.94px;
                                                      font-weight: 400;
                                                      letter-spacing: 0px;
                                                      line-height: 31.78px;
                                                      color: rgba(51, 51, 51, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }

                                                .content_top_right_p2 {
                                                      margin-top: 19px;
                                                      width: 100%;
                                                      height: 106px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 15.96px;
                                                      font-weight: 300;
                                                      letter-spacing: 0;
                                                      line-height: 23.11px;
                                                      color: rgba(102, 102, 102, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }
                                          }
                                    }
                              }

                              .content_botton {
                                    width: 918px;
                                    height:400px;

                                    .content_botton_left {
                                          float: left;
                                          margin-right: 40px;
                                          width: 439px;
                                          height: 400px;
                                          background: rgba(255, 255, 255, 1);
                                          box-shadow: 0px 34.91px 75.8px rgba(0, 0, 0, 0.06);
                                          display: flex;
                                          justify-content: center;

                                          .content_botton_left_wrap {
                                                margin-top: 51px;
                                                width: 357px;
                                                height: 240px;

                                                .content_botton_left_img {
                                                      width: 100%;
                                                      height: 69px;

                                                      .point-icon {
                                                            width: 68px;
                                                            height: 55px;
                                                      }
                                                }

                                                .content_botton_left_p1 {
                                                      margin-top: 23px;
                                                      width: 100%;
                                                      height: 31.92px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 21.94px;
                                                      font-weight: 400;
                                                      letter-spacing: 0;
                                                      line-height: 31.78px;
                                                      color: rgba(51, 51, 51, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }

                                                .content_botton_left_p2 {
                                                      margin-top: 19px;
                                                      width: 100%;
                                                      height: 97.08px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 15.96px;
                                                      font-weight: 300;
                                                      letter-spacing: 0;
                                                      line-height: 23.11px;
                                                      color: rgba(102, 102, 102, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }
                                          }
                                    }

                                    .content_botton_right {
                                          float: left;
                                          width: 439px;
                                          height: 400px;
                                          background: rgba(255, 255, 255, 1);
                                          box-shadow: 0px 34.91px 75.8px rgba(0, 0, 0, 0.06);
                                          display: flex;
                                          justify-content: center;

                                          .content_botton_right_wrap {
                                                margin-top: 51px;
                                                width: 351px;
                                                height: 217px;

                                                .content_botton_right_img {
                                                      width: 100%;
                                                      height: 57px;

                                                      .monitor-icon {
                                                            width: 68px;
                                                            height: 55px;
                                                      }
                                                }

                                                .content_botton_right_p1 {
                                                      margin-top: 31px;
                                                      width: 100%;
                                                      height: 31.92px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 21.94px;
                                                      font-weight: 400;
                                                      letter-spacing: 0;
                                                      line-height: 31.78px;
                                                      color: rgba(51, 51, 51, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }

                                                .content_botton_right_p2 {
                                                      margin-top: 19px;
                                                      width: 100%;
                                                      height: 77.63px;
                                                      opacity: 1;
                                                      /** 文本1 */
                                                      font-size: 15.96px;
                                                      font-weight: 300;
                                                      letter-spacing: 0;
                                                      line-height: 23.11px;
                                                      color: rgba(102, 102, 102, 1);
                                                      text-align: center;
                                                      vertical-align: top;
                                                }
                                          }
                                    }
                              }
                        }
                  }
            }
      }
}
</style>