<template>
  <div class="recommendation">
    <p class="recommendation_top">相关推荐</p>
    <div class="wrap">
      <div class="botton">
        <div class="botton_left" @click="toRoute('eBackupCase')">
          <div class="example">
            <img class="example-icon" :src="example"/>
            <div class="example-title">案例</div>
          </div>
        </div>
        <div class="botton_right" @click="toRoute('eBackupCFile')">
          <div class="product">
            <img class="product-icon" :src="product"/>
            <div class="product-title">产品</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Recommendation",
  data() {
    return {
      example: require('@/assets/recommendation/example.png'),
      exampleBackground: require('@/assets/recommendation/exampleBackground.png'),
      product: require('@/assets/recommendation/product.png'),
    }
  },
  methods: {
    toRoute(name) {
      if (name == "") {
        this.$router.push({name: "home"})
      }
      this.$router.push({name})
    },
  },
  created() {

  },
  computed: {},
  watch: {},

}
</script>

<style lang="scss" scoped>
.botton_left:hover {
  .example-title {
    color: #409EFF!important;
  }
}
.botton_right:hover {
  .product-title {
    color: #409EFF!important;
  }
}
.recommendation {
  width: 1920px;
  height: 452px;

  .recommendation_top {
    padding-top: 70px;
    width: 100%;
    height: 47px;
    /** 文本1 */
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 46.34px;
    color: rgba(34, 34, 34, 1);
    text-align: center;
    vertical-align: top;
  }

  .wrap {
    margin-top: 34px;
    width: 100%;
    height: 220px;

    .botton {
      width: 100%;
      height: 220px;
      display: flex;
      justify-content: center;

      .botton_left {
        cursor: pointer;
        width: 699px;
        height: 220px;
        background: url("../../../assets/recommendation/exampleBackground.png");
        .example {
          margin-top: 12%;
          .example-icon {
            width: 54px;
            height: 50px;
          }
          .example-title {
            display: inline;
            margin-left: 3%;
            /** 文本1 */
            font-size: 26px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 37.65px;
            color: rgb(255, 255, 255);
          }
        }
      }

      .botton_right {
		  cursor: pointer;
        width: 699px;
        height: 220px;
        background: linear-gradient(270deg, rgba(112, 168, 64, 1) 0%, rgba(13, 126, 191, 1) 100%);
        .product{
          margin-top: 12%;
          .product-icon {
            width: 54px;
            height: 50px;

          }

          .product-title {
            display: inline;
            margin-left: 3%;
            font-size: 26px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 37.65px;
            color: rgb(255, 255, 255);
          }
        }

      }
    }
  }

}


</style>