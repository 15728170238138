<template>
	<div class="benefit">
		<p class="benefit_p">方案效益</p>
		<div class="benefit_content">
			<div class="benefit_content_wrap">
				<div class="benefit_content_wrap_content">
					<div class="benefit_content_wrap_content_left">
						<div class="content_left_img">
							<img class="safe-img" :src="anquan"/>
						</div>
						<div class="content_left_p">
							<p>医院的备份数据得到全面的安全保护，降低数据丢失、损坏或被未经授权的访问的风险</p>
						</div>
					</div>
					<div class="benefit_content_wrap_content_center">
						<div class="content_center_img">
							<img class="association-img" :src="association"/>
						</div>
						<div class="content_center_p">
							<p>提供高可靠性和可用性，确保备份数据在发生硬件故障、自然灾害或其他意外情况时能够快速恢复，保障医院业务的连续性</p>
						</div>
					</div>
					<div class="benefit_content_wrap_content_right">
						<div class="content_right_img">
							<img class="backup-img" :src="beifen"/>
						</div>
						<div class="content_right_p">
							<p>提供自动化的备份和恢复功能，减少了手动操作的工作量，简化了备份管理流程，使医院能够更高效地管理备份数据</p>
						</div>
					</div>
				</div>
			</div>
			<div class="benefit_botton">
				<img class="background-img" :src="background"/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Benefit",
	data(){
		return {
			anquan: require('@/assets/benefit/anquan.png'),
			association: require('@/assets/benefit/association.png'),
			beifen: require('@/assets/benefit/beifenhuifushenqing.png'),
			background: require('@/assets/benefit/background20231108.png'),
		}
	},
	methods:{
	},
	created() {

	},
	computed:{
	},
	watch:{

	},

}
</script>

<style lang="scss" scoped>
.benefit{
	width: 1920px;
	height: 574px;
}
.benefit_p{
	padding-top: 78px;
	width: 100%;
	height: 47px;
	opacity: 1;
	/** 文本1 */
	font-size: 31.92px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 46.22px;
	color: rgba(51, 51, 51, 1);
	text-align: center;
	vertical-align: top;
}
.benefit_content{
	margin-top: 54px;
	width: 100%;
	height: 395px;
}
.benefit_content_wrap{
	width: 100%;
	height: 200px;
	/* 子元素居中 */
	display: flex;
	justify-content: center; /* 子元素水平居中 */
}
.benefit_content_wrap_content{
	width: 1400px;
	height: 200px;
}
.benefit_content_wrap_content_left{
	float: left;
	margin-top: 40px;
	margin-right: 40px;
	width: 437px;
	height: 160px;
	opacity: 1;
	border-radius: 5.98px;
	background: rgba(230, 246, 255, 1);
	border: 1px solid rgba(173, 225, 255, 1);
	box-shadow: 0 34.91px 75.8px  rgba(0, 0, 0, 0.1);
	box-sizing:border-box;
}
.benefit_content_wrap_content_center{
	margin-right: 40px;
	float: left;
	width: 437px;
	height: 159.58px;
	opacity: 1;
	border-radius: 5.98px;
	background: rgba(228, 240, 218, 1);
	border: 1px solid rgba(198, 240, 163, 1);
	box-shadow: 0 34.91px 75.8px  rgba(0, 0, 0, 0.1);
	box-sizing:border-box;
}
.benefit_content_wrap_content_right{
	float: left;
	margin-top: 40px;
	width: 437px;
	height: 159.58px;
	opacity: 1;
	border-radius: 5.98px;
	background: rgba(230, 246, 255, 1);
	border: 1px solid rgba(173, 225, 255, 1);
	box-shadow: 0 34.91px 75.8px  rgba(0, 0, 0, 0.1);
	box-sizing:border-box;
}
.content_left_img{
	float: left;
	margin-top: 46px;
	margin-left: 32px;
	width: 25px;
	height: 32px;
  .safe-img {
    width: 25px;
    height: 32px;
  }
}
.content_left_p{
	float: left;
	margin-top: 41px;
	margin-left: 23px;
	width: 318px;
	height: 99px;
}
.content_left_p p{
	opacity: 1;
	/** 文本1 */
	font-size: 15.96px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.11px;
	color: rgba(102, 102, 102, 1);
	text-align: left;
	vertical-align: top;
}
.content_center_img{
	float: left;
	margin-top: 48px;
	margin-left: 31px;
	width: 26px;
	height: 35px;
  .association-img {
    width: 26px;
    height: 35px;
  }
}
.content_center_p{
	float: left;
	margin-top: 41px;
	margin-left: 23px;
	width: 322px;
	height: 99px;
}
.content_center_p p{
	opacity: 1;
	/** 文本1 */
	font-size: 15.96px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.11px;
	color: rgba(102, 102, 102, 1);
	text-align: left;
	vertical-align: top;
}
.content_right_img{
	float: left;
	margin-top: 47px;
	margin-left: 31px;
	width: 32px;
	height: 32px;
  .backup-img {
    width: 32px;
    height: 32px;
  }
}
.content_right_p{
	float: left;
	margin-top: 41px;
	margin-left: 17px;
	width: 326px;
	height: 99px;
}
.content_right_p p{
	opacity: 1;
	/** 文本1 */
	font-size: 15.96px;
	font-weight: 300;
	letter-spacing: 0;
	line-height: 23.11px;
	color: rgba(102, 102, 102, 1);
	text-align: left;
	vertical-align: top;
}
.benefit_botton{
	margin-top: -19px;
	width: 100%;
	height: 269px;
  .background-img {
    width: 100%;
    height: 269px;
  }
}
.benefit_botton img{
	width: 100%;
	height: 269px;
}
</style>