<template>
	<div class="eMove_value">
		<div class="eMove_value_wrap">
			<div class="eMove_value_wrap_background">
				<img class="eMove_value_wrap_background_img" :src="eMoveBackground"/>
			</div>
			<div class="eMove_value_content">
				<p class="eMove_value_content_p">价值</p>
				<div class="eMove_value_content_div_top">
					<div class="eMove_value_content_div1">
						<div class="eMove_value_content_div1_top">
							<img class="eMove_value_content_div1_img" :src="tigaoxiaolv"/>
						</div>
						<p class="eMove_value_content_div1_p1">提高效率</p>
						<p class="eMove_value_content_div1_p2">通过e键搬工具，用户可以快速将电脑应用迁移到新电脑，节省了传统迁移过程中繁琐的配置和安装时间，提高了工作效率</p>
					</div>
					<div class="eMove_value_content_div2">
						<div class="eMove_value_content_div2_top">
							<img class="eMove_value_content_div2_img" :src="jieyuechengben"/>
						</div>
						<p class="eMove_value_content_div2_p1">节约成本</p>
						<p class="eMove_value_content_div2_p2">实现云端迁移及存储，只需支付工具的使用费用，实现了成本的节约</p>
					</div>
				</div>
				<div class="eMove_value_content_div_botton">
					<div class="eMove_value_content_div3">
						<div class="eMove_value_content_div3_top">
							<img class="eMove_value_content_div3_img" :src="linghuoxing"/>
						</div>
						<p class="eMove_value_content_div3_p1">提升灵活性</p>
						<p class="eMove_value_content_div3_p2">支持主流操作系统、数据库及存储系统，轻松实现升级与扩容</p>
					</div>
					<div class="eMove_value_content_div4">
						<div class="eMove_value_content_div4_top">
							<img class="eMove_value_content_div4_img" :src="shujuanquan"/>
						</div>
						<p class="eMove_value_content_div4_p1">数据安全</p>
						<p class="eMove_value_content_div4_p2">通过加密和保护用户的数据，e键搬工具确保了数据的安全性，防止数据丢失和泄露的风险</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EMoveValue",
	data() {
		return {
			eMoveBackground: require('@/assets/eMove/eMove-value/eMoveBackground.png'),
			tigaoxiaolv: require('@/assets/eMove/eMove-value/4.png'),
			jieyuechengben: require('@/assets/eMove/eMove-value/3.png'),
			linghuoxing: require('@/assets/eMove/eMove-value/2.png'),
			shujuanquan: require('@/assets/eMove/eMove-value/1.png'),
		}
	},
	methods: {},
	created() {

	},
	computed: {},
	watch: {},

}
</script>

<style lang="scss" scoped>
.eMove_value{
	width: 1920px;
	height: 708px;
	display: flex;
	justify-content: center;
	.eMove_value_wrap{
		position: relative;
		margin-top: 78px;
		width: 928px;
		height: 630px;
		.eMove_value_wrap_background{
			position: absolute;
			width: 100%;
			height: 630px;
			.eMove_value_wrap_background_img{
				width: 100%;
				height: 630px;
			}
		}
		.eMove_value_content{
			position: absolute;
			width: 100%;
			height: 630px;
			.eMove_value_content_p{
				margin-top: 57px;
				width: 100%;
				height: 47px;
				opacity: 1;
				/** 文本1 */
				font-size: 31.92px;
				font-weight: 700;
				letter-spacing: 0;
				line-height: 46.22px;
				color: rgba(34, 34, 34, 1);
				text-align: center;
				vertical-align: top;
			}
			.eMove_value_content_div_top{
				margin-top: 2px;
				width: 100%;
				height: 225px;
				.eMove_value_content_div1{
					float: left;
					margin-left: -110px;
					width: 245px;
					height: 225px;
					.eMove_value_content_div1_top{
						width: 100%;
						height: 35px;
						.eMove_value_content_div1_img{
							float: right;
							width: 35px;
							height: 35px;
						}
					}
					.eMove_value_content_div1_p1{
						margin-top: 15px;
						width: 100%;
						height: 34.91px;
						opacity: 1;
						/** 文本1 */
						font-size: 23.94px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 34.66px;
						color: rgba(51, 51, 51, 1);
						text-align: right;
						vertical-align: top;
					}
					.eMove_value_content_div1_p2{
						float: right;
						margin-top: 11px;
						width: 241px;
						height: 129.57px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(102, 102, 102, 1);
						text-align: right;
						vertical-align: top;
					}
				}
				.eMove_value_content_div2{
					float: right;
					margin-right: -105px;
					width: 243px;
					height: 200px;
					.eMove_value_content_div2_top{
						width: 100%;
						height: 37px;
						.eMove_value_content_div2_img{
							float: left;
							width: 38px;
							height: 37px;
						}
					}
					.eMove_value_content_div2_p1{
						margin-top: 13px;
						width: 100%;
						height: 34.91px;
						opacity: 1;
						/** 文本1 */
						font-size: 23.94px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 34.66px;
						color: rgba(51, 51, 51, 1);
						text-align: left;
						vertical-align: top;
					}
					.eMove_value_content_div2_p2{
						margin-top: 11px;
						width: 239.88px;
						height: 104.64px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(102, 102, 102, 1);
						text-align: left;
						vertical-align: top;
					}
				}
			}
			.eMove_value_content_div_botton{
				margin-top: 50px;
				width: 100%;
				height: 210px;
				.eMove_value_content_div3{
					float: left;
					margin-left: -180px;
					width: 243px;
					height: 202px;
					.eMove_value_content_div3_top{
						width: 100%;
						height: 34px;
						.eMove_value_content_div3_img{
							float: right;
							width: 33px;
							height: 34px;
						}
					}
					.eMove_value_content_div3_p1{
						margin-top: 12px;
						width: 100%;
						height: 34.91px;
						opacity: 1;
						/** 文本1 */
						font-size: 23.94px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 34.66px;
						color: rgba(51, 51, 51, 1);
						text-align: right;
						vertical-align: top;
						}
					.eMove_value_content_div3_p2{
						margin-top: 11px;
						width: 241.37px;
						height: 110.1px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0;
						line-height: 20.22px;
						color: rgba(102, 102, 102, 1);
						text-align: right;
						vertical-align: top;

					}
				}
				.eMove_value_content_div4{
					float: right;
					margin-top: 11px;
					margin-right: -179px;
					width: 240px;
					height: 210px;
					.eMove_value_content_div4_top{
						width: 100%;
						height: 36px;
						.eMove_value_content_div4_img{
							float: left;
							width: 33px;
							width: 36px;
						}
					}
					.eMove_value_content_div4_p1{
						margin-top: 9px;
						width: 100%;
						height: 34.91px;
						opacity: 1;
						/** 文本1 */
						font-size: 23.94px;
						font-weight: 400;
						letter-spacing: 0;
						line-height: 34.66px;
						color: rgba(51, 51, 51, 1);
						text-align: left;
						vertical-align: top;
					}
					.eMove_value_content_div4_p2{
						margin-top: 11px;
						width: 237.88px;
						height: 119.1px;
						opacity: 1;
						/** 文本1 */
						font-size: 13.96px;
						font-weight: 300;
						letter-spacing: 0px;
						line-height: 20.22px;
						color: rgba(102, 102, 102, 1);
						text-align: left;
						vertical-align: top;

					}
				}
			}
		}
	}
}

</style>