<template>
  <div class="center">
    <div class="introduce" ref="introduce">
      <div class="introduce-text">
        <div class="title">
          广州数源畅联科技有限公司（Linkstor）是一家专注于数据领域与科技服务的高新技术企业
        </div>
        <div class="middle-divider" />
        <div class="text">
          数源畅联自2017年创立以来，致力于推进数字化技术的创新服务，围绕数据安全、连接安全、智能服务、物联网四大产品方向，形成容灾、备份、迁移、网络安全连接、私有云建设、物联网等产品及专业服务，服务范围涵盖运营商、医疗、教育、政务等多领域，并获得了市场的一致认可！
        </div>
        <div class="text">
          数源畅联一直坚持创新突破的中心理念，将技术研发和人才培养作为发展目标。在公司本部设立了专门的研发部及技术部门，且在上海、四川、武汉建立了长期稳定的合作研发团队，以自主研发创新的产品和技术服务为客户提供数据领域要素的解决方案，帮助客户从容应对数字化转型的业务挑战。
        </div>
        <div class="text">
          未来，数源畅联仍秉持不断创新驱动变革的发展理念，以技术引领发展，为客户提供专业、高效、优质的服务！
        </div>
      </div>
      <div class="introduce-img">
        <img class="introduce-main" :src="introduceImg">
      </div>
    </div>
    <div class="mountain" ref="mountain">
      <img class="mountain-img" :src="mountain"/>
    </div>
    <div class="certificate">
      <div class="title">
        荣誉资质
      </div>
      <div class="honor" ref="honor">
		  <!--图片轮播图--start-->
        <div class="imgWatch">
          <el-carousel class="carousel-main" ref="carousel" :autoplay="true" type="card" indicator-position="none" arrow="never" height="100%">
            <el-carousel-item v-for="item in certificates" :key="item">
<!--              <h3 class="medium">{{ item }}</h3>-->
				<img class="aptitudeCertificate_img" :width="item.width" :height="item.height" :src="item.credentials"/>
            </el-carousel-item>
          </el-carousel>
          <el-button class="last" @click="last">&lt;</el-button>
          <el-button class="next" @click="next">&gt;</el-button>
        </div>
		  <!--图片轮播图--end-->
        <div class="honor-list">
          <el-tabs v-model="active" :stretch="true">
            <el-tab-pane label="资质" name="aptitude">
              <el-timeline class="timeline">
                <el-timeline-item
                    v-for="(activity, index) in aptitudeActivities"
                    :key="index"
                    :timestamp="activity.timestamp"
                    :color="activity.color">
                  {{activity.content}}
                </el-timeline-item>
              </el-timeline>
            </el-tab-pane>
            <el-tab-pane label="软著" name="Softness">
              <el-timeline class="timeline">
                <el-timeline-item
                    v-for="(activity, index) in softnessActivities"
                    :key="index"
                    :timestamp="activity.timestamp"
                    :color="activity.color">
                  {{activity.content}}
                </el-timeline-item>
              </el-timeline>
            </el-tab-pane>
            <el-tab-pane label="荣誉" name="honor">
              <el-timeline class="timeline">
                <el-timeline-item
                    v-for="(activity, index) in honorActivities"
                    :key="index"
                    :timestamp="activity.timestamp"
                    :color="activity.color">
                  {{activity.content}}
                </el-timeline-item>
              </el-timeline>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	name: "index",
	data() {
		return {
			introduceImg: require('../../assets/about/introduceImg.png'),
			active: 'aptitude',
			aptitudeActivities: [
				{
					content: '广东省守合同重信用企业-公示证书',
					timestamp: '2021年',
					color: '#70a840'
				},
				{
					content: 'ITSS信息技术服务标准证书',
					timestamp: '2021年',
					color: '#70a840'
				},
				{
					content: '.ISO 27001-信息安全管理体系认证证书',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: 'ISO14001-环境管理体系认证证书',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '高新技术企业证书',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '设计、施工、维修资格证（四级）',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '增值电信业务经营许可证',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: 'ISO 9001-质量管理体系认证证书',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '信息系统建设和服务能力CS1级证书',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '软件企业证书',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '诚信经营示范企业',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '企业信用等级评价证书',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '企业资信等级评价证书',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '质量服务诚信企业',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '重服务守信用企业',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '重合同守信用企业',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '重质量守信用企业',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '会员证-广东省信息协会理事单位',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: 'ISO20000-信息技术服务管理体系认证证书',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '中国电子信息行业联合会会员',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '广东软件行业协会-会员证',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '企业信用评价AAA级信用企业',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: 'ISO27017-云服务信息安全管理体系认证证书',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: 'ISO27018-公有云中个人可识别信息保护管理体系认证证书',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '电信网码号资源使用证书',
					timestamp: '2023年',
					color: '#70a840'
				}
			],
			aptitudeCertificate:[
				{
					credentials: require('@/assets/about-center/aptitude/aptitude1.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude2.png'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude3.png'),
          width: '100%',
          height: '98%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude4.jpg'),
          width: '100%',
          height: '98%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude5.png'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude6.png'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude7.png'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude8.png'),
          width: '100%',
          height: '98%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude9.png'),
          width: '100%',
          height: '98%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude10.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude11.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude12.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude13.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude14.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude15.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude16.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude17.png'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude18.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude19.jpg'),
          width: '100%',
          height: '98%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude20.png'),
          width: '100%',
          height: '98%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude21.png'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude22.png'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude23.jpg'),
          width: '100%',
          height: '98%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude24.jpg'),
          width: '100%',
          height: '98%',
				},
				{
					credentials: require('@/assets/about-center/aptitude/aptitude25.png'),
          width: '100%',
          height: '98%',
				}
			],
			softnessActivities: [
				{
					content: 'ADS归档备份及重复删除系统软件V1.0',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: 'CDP持续数据保护客户端软件（Linux版本)V1.0',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: 'CDP持续数据保护客户端软件（Window版本)V1.0',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: 'HCS超融合存储系统软件V1.0',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: 'HSM分级存储管理软件V1.0',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: 'OCS云存储系统软件V1.0',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: 'WSC广域网双机高可用管理软件（Linux版本）V1.0',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: 'WSC广域网双机高可用管理软件（Window版本）V1.0',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: '数源畅联备份容灾软件V2.0—软著',
					timestamp: '2021年',
					color: '#70a840'
				},
				{
					content: '数源畅联订单信息管理软件V1.0',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '数源畅联多租户平台管理软件V1.0',
					timestamp: '2020年',
					color: '#70a840'
				},
				{
					content: '数源畅联客户关系管理软件V1.0',
					timestamp: '2020年',
					color: '#70a840'
				},
				{
					content: '数源畅联迁移服务软件V1.0—软著',
					timestamp: '2021年',
					color: '#70a840'
				},
				{
					content: '数源畅联容灾备份服务订单管理软件V1.0',
					timestamp: '2019年',
					color: '#70a840'
				},
				{
					content: '数源畅联容灾备份服务管理软件V1.0',
					timestamp: '2019年',
					color: '#70a840'
				},
				{
					content: '数源畅联审批信息管理软件V1.0',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '数源畅联移动端订单信息管理软件V1.0',
					timestamp: '2020年',
					color: '#70a840'
				},
				{
					content: '数源畅联云融盒门户管理软件V4.1.7',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: '数源合作代理商管理平台',
					timestamp: '2021年',
					color: '#70a840'
				},
				{
					content: '数源统一财务报销软件',
					timestamp: '2020年',
					color: '#70a840'
				},
				{
					content: '数源员工管理考核平台V1.0',
					timestamp: '2021年',
					color: '#70a840'
				},
				{
					content: '数源畅联备份容灾软件V2.0—软著',
					timestamp: '2021年',
					color: '#70a840'
				},
				{
					content: '数源云融盒管理门户软件V1.0',
					timestamp: '2017年',
					color: '#70a840'
				},
				{
					content: '沃云备多控制端共享弹性带宽管理系统V1.0',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: '沃云备管理软件V1.0',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: '数源畅联移动端审批订单信息管理软件V1.0',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '云均云桌面终端运营系统V1.0',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '云玓云桌面订单审批管理软件',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '云玓云桌面后台管理软件',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '数源畅联云维塔软件V1.0',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '云电脑网络连接状态检测工具平台V1.0',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '搬家助手软件V1.0',
					timestamp: '2023年',
					color: '#70a840'
				},
				{
					content: '换机助手软件V1.0',
					timestamp: '2023年',
					color: '#70a840'
				}
			],
			softnessCertificate: [
				{
					credentials: require('@/assets/about-center/softness/softness1.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness2.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness3.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness4.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness5.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness6.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness7.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness8.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness9.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness10.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness11.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness12.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness13.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness14.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness15.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness16.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness17.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness18.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness19.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness20.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness21.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness22.png'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness23.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness24.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness25.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness26.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness27.png'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness28.png'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness29.png'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness30.jpg'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness31.png'),
          width: '100%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/softness/softness32.png'),
          width: '100%',
          height: '100%',
				}
			],
			honorActivities: [
				{
					content: '广东软件行业协会会员单位',
					timestamp: '2018年',
					color: '#70a840'
				},
				{
					content: '信息化项目移动中山分公司优秀合作伙伴',
					timestamp: '2019年',
					color: '#70a840'
				},
				{
					content: '移动年度优秀合作伙伴',
					timestamp: '2019年',
					color: '#70a840'
				},
				{
					content: '中国电信最佳合作伙伴',
					timestamp: '2019年',
					color: '#70a840'
				},
				{
					content: '广东珠海分公司战略合作牌',
					timestamp: '2020年',
					color: '#70a840'
				},
				{
					content: '移动万象计划优秀合作伙伴',
					timestamp: '2020年',
					color: '#70a840'
				},
				{
					content: '移动优选商城合作伙伴授牌',
					timestamp: '2020年',
					color: '#70a840'
				},
				{
					content: '广东省网络安全行业科学技术奖证书—基于云原生的端到端容灾备份数据智能保护系统及服务平台（二等奖）',
					timestamp: '2021年',
					color: '#70a840'
				},
				{
					content: '中国移动合作伙伴龙虎榜最佳贡献奖',
					timestamp: '2021年',
					color: '#70a840'
				},
				{
					content: '广东软件风云榜卓越IT运维企业',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '2022年”移动云杯“算力网络应用创新大赛-赛道优秀奖',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '信息化应用创新优秀方案-广东省信息协会',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '行业应用集团级金牌合作伙伴',
					timestamp: '2022年',
					color: '#70a840'
				},
				{
					content: '2023移动云生态合作伙伴最佳合作奖',
					timestamp: '2023年',
					color: '#70a840'
				},
			],
			honorCertificate: [
				{
					credentials: require('@/assets/about-center/honor/honor1.png'),
          width: '100%',
          height: '98%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor2.png'),
          width: '75.3%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor3.png'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor4.png'),
          width: '75.3%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor5.png'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor6.png'),
          width: '75.3%',
          height: '100%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor7.png'),
          width: '100%',
          height: '98%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor8.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor9.png'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor10.png'),
          width: '100%',
          height: '98%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor11.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor12.png'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor13.jpg'),
          width: '100%',
          height: '46%',
				},
				{
					credentials: require('@/assets/about-center/honor/honor14.jpg'),
          width: '100%',
          height: '46%',
				}
			],
      mountain: require('../../assets/about/mountain.png'),
    	}
  	},
  mounted() {
    if(this.$route.params){
      if(this.$route.params.id == 'introduce') {
        this.$refs.introduce.scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block:    "start"  // 上边框与视窗顶部平齐。默认值
        });
      } else if(this.$route.params.id == 'mountain') {
        this.$refs.mountain.scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block:    "start"  // 上边框与视窗顶部平齐。默认值
        });
      } else if(this.$route.params.id == 'honor') {
        this.$refs.honor.scrollIntoView({
          behavior: "smooth",  // 平滑过渡
          block:    "start"  // 上边框与视窗顶部平齐。默认值
        });
      }
    }
  },
  methods: {
    last() {
      this.$refs.carousel.prev()
    },
    next() {
      this.$refs.carousel.next()
    }
  },
	computed: {
		certificates() {
			let certificate = [];
			if (this.active === 'aptitude'){
				certificate = this.aptitudeCertificate;
			}else if (this.active === 'Softness'){
				certificate = this.softnessCertificate;
			}else if (this.active === 'honor'){
				certificate = this.honorCertificate;
			}
			return certificate;
		}
	}
}
</script>

<style lang="scss" scoped>
.introduce {
  margin-top: 80px;
  width: 1920px;
  height: 783px;
  display: flex;
  justify-content: center;
  .introduce-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .title {
      width: 704px;
      height: 84px;
      opacity: 1;
      /** 文本1 */
      font-size: 32px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 42px;
      color: rgba(102, 102, 102, 1);
      text-align: left;
      vertical-align: top;
    }
    .middle-divider {
      margin-top: 30px;
      width: 73px;
      height: 14px;
      opacity: 1;
      border-radius: 7px;
      background: linear-gradient(270deg, rgba(13, 126, 191, 1) 0%, rgba(112, 168, 64, 1) 100%);
    }
    .text {
      margin-top: 30px;
      width: 703.1px;
      height: 100px;
      /** 文本1 */
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0px;
      line-height: 26.06px;
      color: rgba(102, 102, 102, 1);
      text-align: left;
      vertical-align: top;

    }
  }
  .introduce-img {
    margin-left: 30px;
    .introduce-main {
      width: 668px;
      height: 783px;
    }
  }
}
.mountain {
  margin-top: 80px;
  width: 1920px;
  height: 883px;
  .mountain-img {
    width: 1920px;
    height: 883px;
  }
}
.certificate {
  width: 1920px;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  .title {
    margin-left: 350px;
    margin-top: 80px;
    width: 144px;
    height: 53px;
    /** 文本1 */
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 52.13px;
    color: rgba(51, 51, 51, 1);
    text-align: left;
    vertical-align: top;
  }
  .honor {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .imgWatch {
      width: 800px;
      height: 510px;
      .carousel-main {
        width: 800px;
        height: 510px;
      }
      .last {
        width: 48px;
        height: 48px;
        opacity: 1;
        background: rgba(13, 126, 191, 1);
        font-weight: 800;
        color: white;
      }
      .next {
        width: 48px;
        height: 48px;
        opacity: 1;
        background: rgba(13, 126, 191, 1);
        font-weight: 800;
        color: white;
      }
      //.el-carousel__item:nth-child(2n) {
      //  background-color: #99a9bf;
      //}
      //.el-carousel__item:nth-child(2n+1) {
      //  background-color: #d3dce6;
      //}
    }
    .honor-list {
      width: 400px;
      margin-left: 50px;
      text-align: left;
      .timeline {
		  overflow-y: scroll;
		  height: 500px;
        margin-top: 20px;
        margin-left: 40px;
      }
    }
  }
}
//.aptitudeCertificate_img{
//	width: 100%;
//	height: 100%;
//}
</style>