<template>
	<div class="center">
		<Cfile/>
		<Function/>
	</div>
</template>

<script>
import Cfile from "@/components/eBackup-cFile/cfile/index.vue";
import Function from "@/components/eBackup-cFile/function/index.vue";

export default {
	name: "index",
	components: {Function, Cfile}
}
</script>

<style scoped>

</style>