<template>
	<div class="eManage_value">
		<div class="eManage_value_content">
			<p class="eManage_value_content_p">价值</p>
			<div class="eManage_value_content_content">
				<div class="eManage_value_content_content_background">
					<img class="eManage_value_content_content_background_img" :src="valueBackground"/>
				</div>
				<div class="eManage_value_content_content_wrap">
					<div class="eManage_value_content_content_div1">
						<div class="eManage_value_content_content_div1_wrap">
							<img class="eManage_value_content_content_div1_wrap_img" :src="value1"/>
							<p class="eManage_value_content_content_div1_wrap_p1">集中管控</p>
							<div class="eManage_value_content_content_div1_wrap_div">
								<div class="eManage_value_content_content_div1_wrap_div_center"></div>
							</div>
							<p class="eManage_value_content_content_div1_wrap_p2">全面保护数据安全</p>
						</div>
					</div>
					<div class="eManage_value_content_content_div2">
						<div class="eManage_value_content_content_div2_wrap">
							<img class="eManage_value_content_content_div2_wrap_img" :src="value2"/>
							<p class="eManage_value_content_content_div2_wrap_p1">高效运维</p>
							<div class="eManage_value_content_content_div2_wrap_div">
								<div class="eManage_value_content_content_div2_wrap_div_center"></div>
							</div>
							<p class="eManage_value_content_content_div2_wrap_p2">提升IT控制力和效率</p>
						</div>
					</div>
					<div class="eManage_value_content_content_div3">
						<div class="eManage_value_content_content_div3_wrap">
							<img class="eManage_value_content_content_div3_wrap_img" :src="value3"/>
							<p class="eManage_value_content_content_div3_wrap_p1">随身桌面</p>
							<div class="eManage_value_content_content_div3_wrap_div">
								<div class="eManage_value_content_content_div3_wrap_div_center"></div>
							</div>
							<p class="eManage_value_content_content_div3_wrap_p2">高效工作切换便捷</p>
						</div>
					</div>
					<div class="eManage_value_content_content_div4">
						<div class="eManage_value_content_content_div4_wrap">
							<img class="eManage_value_content_content_div4_wrap_img" :src="value4"/>
							<p class="eManage_value_content_content_div4_wrap_p1">成本控制</p>
							<div class="eManage_value_content_content_div4_wrap_div">
								<div class="eManage_value_content_content_div4_wrap_div_center"></div>
							</div>
							<p class="eManage_value_content_content_div4_wrap_p2">5年综合使用成本比传统方式节约60%</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "EManageValue",
	data() {
		return {
			valueBackground: require('@/assets/eManage/eManage_value/beijing.png'),
			value1: require('@/assets/eManage/eManage_value/1.png'),
			value2: require('@/assets/eManage/eManage_value/2.png'),
			value3: require('@/assets/eManage/eManage_value/3.png'),
			value4: require('@/assets/eManage/eManage_value/4.png'),
		}
	},
	methods: {},
	created() {},
	computed: {},
	watch: {},
}
</script>

<style lang="scss" scoped>
.eManage_value{
	width: 1920px;
	height: 730px;
	display: flex;
	justify-content: center;
	background: rgba(245, 248, 255, 1);
	.eManage_value_content{
		width: 1920px;
		height: 100%;
		.eManage_value_content_p{
			margin-top: 71px;
			width: 100%;
			height: 47px;
			opacity: 1;
			/** 文本1 */
			font-size: 31.92px;
			font-weight: 700;
			letter-spacing: 0;
			line-height: 46.22px;
			color: rgba(34, 34, 34, 1);
			text-align: center;
			vertical-align: top;
		}
		.eManage_value_content_content{
			margin-top: 33px;
      margin-left: 250px;
			position: relative;
			width: 1395px;
			height: 501px;
			.eManage_value_content_content_background{
				position: absolute;
				width: 1395px;
				height: 501px;
				.eManage_value_content_content_background_img{
					width: 1395px;
					height: 501px;
				}
			}
			.eManage_value_content_content_wrap{
				position: absolute;
				width: 1395px;
				height: 501px;
				.eManage_value_content_content_div1{
					float: left;
					margin-right: 1px;
					width: 320px;
					height: 501px;
					display: flex;
					justify-content: center;
					.eManage_value_content_content_div1_wrap{
						margin-top: 145px;
						width: 128px;
						height: 225px;
						.eManage_value_content_content_div1_wrap_img{
							width: 63px;
							height: 64px;
						}
						.eManage_value_content_content_div1_wrap_p1{
							margin-top: 77px;
							width: 100%;
							height: 34.91px;
							opacity: 1;
							/** 文本1 */
							font-size: 23.94px;
							font-weight: 400;
							letter-spacing: 0;
							line-height: 34.66px;
							color: rgba(255, 255, 255, 1);
							text-align: center;
							vertical-align: top;
						}
						.eManage_value_content_content_div1_wrap_div{
							margin-top: 4px;
							width: 100%;
							height: 6px;
							display: flex;
							justify-content: center;
							.eManage_value_content_content_div1_wrap_div_center{
								width: 45.88px;
								height: 6px;
								opacity: 1;
								border-radius: 2.99px;
								background: rgba(255, 255, 255, 1);
							}
						}
						.eManage_value_content_content_div1_wrap_p2{
							margin-top: 15px;
							width: 100%;
							height: 23.94px;
							opacity: 1;
							/** 文本1 */
							font-size: 15.96px;
							font-weight: 300;
							letter-spacing: 0;
							line-height: 23.11px;
							color: rgba(255, 255, 255, 1);
							text-align: center;
							vertical-align: top;
						}
					}
				}
				.eManage_value_content_content_div2{
					float: left;
					margin-right: 1px;
					width: 320px;
					height: 501px;
					display: flex;
					justify-content: center;
					.eManage_value_content_content_div2_wrap{
						margin-top: 143px;
						width: 142px;
						height: 230px;
						.eManage_value_content_content_div2_wrap_img{
							width: 63px;
							height: 68px;
						}
						.eManage_value_content_content_div2_wrap_p1{
							margin-top: 75px;
							width: 100%;
							height: 35px;
							opacity: 1;
							/** 文本1 */
							font-size: 23.94px;
							font-weight: 400;
							letter-spacing: 0;
							line-height: 34.66px;
							color: rgba(255, 255, 255, 1);
							text-align: center;
							vertical-align: top;
						}
						.eManage_value_content_content_div2_wrap_div{
							margin-top: 5px;
							width: 100%;
							height: 6px;
							display: flex;
							justify-content: center;
							.eManage_value_content_content_div2_wrap_div_center{
								width: 46px;
								height: 6px;
								opacity: 1;
								border-radius: 2.99px;
								background: rgba(255, 255, 255, 1);
							}
						}
						.eManage_value_content_content_div2_wrap_p2{
							margin-top: 17px;
							width: 100%;
							height: 24px;
							opacity: 1;
							/** 文本1 */
							font-size: 15.96px;
							font-weight: 300;
							letter-spacing: 0;
							line-height: 23.11px;
							color: rgba(255, 255, 255, 1);
							text-align: center;
							vertical-align: top;
						}
					}
				}
				.eManage_value_content_content_div3{
					float: left;
					margin-right: 1px;
					width: 320px;
					height: 501px;
					display: flex;
					justify-content: center;
					.eManage_value_content_content_div3_wrap{
						margin-top: 145px;
						width: 128px;
						height: 228px;
						.eManage_value_content_content_div3_wrap_img{
							width: 64px;
							height: 64px;
						}
						.eManage_value_content_content_div3_wrap_p1{
							margin-top: 77px;
							width: 100%;
							height: 35px;
							opacity: 1;
							/** 文本1 */
							font-size: 23.94px;
							font-weight: 400;
							letter-spacing: 0;
							line-height: 34.66px;
							color: rgba(255, 255, 255, 1);
							text-align: center;
							vertical-align: top;
						}
						.eManage_value_content_content_div3_wrap_div{
							width: 100%;
							height: 6px;
							display: flex;
							justify-content: center;
							.eManage_value_content_content_div3_wrap_div_center{
								width: 46px;
								height: 6px;
								opacity: 1;
								border-radius: 2.99px;
								background: rgba(255, 255, 255, 1);
							}
						}
						.eManage_value_content_content_div3_wrap_p2{
							margin-top: 17px;
							width: 100%;
							height: 24px;
							opacity: 1;
							/** 文本1 */
							font-size: 15.96px;
							font-weight: 300;
							letter-spacing: 0;
							line-height: 23.11px;
							color: rgba(255, 255, 255, 1);
							text-align: center;
							vertical-align: top;
						}
					}
				}
				.eManage_value_content_content_div4{
					float: left;
					width: 320px;
					height: 501px;
					display: flex;
					justify-content: center;
					.eManage_value_content_content_div4_wrap{
						margin-top: 146px;
						width: 264px;
						height: 227px;
						.eManage_value_content_content_div4_wrap_img{
							width: 54px;
							height: 63px;
						}
						.eManage_value_content_content_div4_wrap_p1{
							margin-top: 77px;
							width: 100%;
							height: 35px;
							opacity: 1;
							/** 文本1 */
							font-size: 23.94px;
							font-weight: 400;
							letter-spacing: 0;
							line-height: 34.66px;
							color: rgba(255, 255, 255, 1);
							text-align: center;
							vertical-align: top;
						}
						.eManage_value_content_content_div4_wrap_div{
							margin-top: 5px;
							width: 100%;
							height: 6px;
							display: flex;
							justify-content: center;
							.eManage_value_content_content_div4_wrap_div_center{
								width: 46px;
								height: 6px;
								opacity: 1;
								border-radius: 2.99px;
								background: rgba(255, 255, 255, 1);
							}
						}
						.eManage_value_content_content_div4_wrap_p2{
							margin-top: 17px;
							width: 100%;
							height: 23.94px;
							opacity: 1;
							/** 文本1 */
							font-size: 15.96px;
							font-weight: 300;
							letter-spacing: 0;
							line-height: 23.11px;
							color: rgba(255, 255, 255, 1);
							text-align: center;
							vertical-align: top;
						}
					}
				}
			}
		}
	}
}
</style>